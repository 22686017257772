import React, { useState } from "react";
import { Form, Menu } from "semantic-ui-react";
import { MarkdownEditor, PlacesDropdown } from "../..";
import { PLACE_FORM_TABS, PLACE_TYPES } from "../../../constants";
import TemplatePlace from "./utils/templatePlace";



const QuestionRange = ({place, onChange}) => {
    const [activePage, setActivePage] = useState(PLACE_FORM_TABS.MAIN.key)
    
    return (
        <Form>
            <TemplatePlace 
                place={place} 
                onChange={onChange} 
                activePage={activePage} 
                setActivePage={setActivePage}
                additionalMenu={
                    <Menu.Item
                        content={PLACE_FORM_TABS.ANSWERS.title}
                        active={activePage === PLACE_FORM_TABS.ANSWERS.key}
                        onClick={() => setActivePage(PLACE_FORM_TABS.ANSWERS.key)}/>
                }/>
            {
                activePage === PLACE_FORM_TABS.MAIN.key &&
                <>
                    <Form.Input
                        label="Заголовок вопроса"
                        value={place.title}
                        placeholder="Введите заголовок"
                        required
                        onChange={(e) => onChange({title: e.target.value})}/>
                    <Form.Field>
                        <label>Родительское сообщение</label>
                        <PlacesDropdown
                            placeholder={'Без родительского сообщения'}
                            value={place.parent_message}
                            type={PLACE_TYPES.message}
                            onChange={(parent_message) => onChange({parent_message})}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Текст</label>
                        <MarkdownEditor
                            value={place.settings.text}
                            onChange={(text) => onChange({settings: {...place.settings, text}})}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Текст для женского обращения</label>
                        <MarkdownEditor
                            value={place.settings.female_text}
                            onChange={(female_text) => onChange({settings: {...place.settings, female_text}})}/>
                    </Form.Field>
                    <Form.TextArea
                        label="Описание"
                        value={place.description}
                        placeholder="Введите описание"
                        onChange={(e) => onChange({description: e.target.value})}/>
                </>
            }
            {
                activePage === PLACE_FORM_TABS.ANSWERS.key && 
                <>
                    <Form.Input
                        value={(place.answer || {}).answer}
                        label="Верный ответ"
                        placeholder="Введите верный ответ"
                        required
                        onChange={(e) => onChange({answer: {answer: e.target.value}})}/>
                    <Form.Field required>
                        <label>Комментарий после верного ответа</label>
                        <MarkdownEditor
                            value={place.settings.comment}
                            onChange={(comment) => onChange({settings: {...place.settings, comment}})}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Женский комментарий после верного ответа</label>
                        <MarkdownEditor
                            value={place.settings.female_comment}
                            onChange={(female_comment) => onChange({settings: {...place.settings, female_comment}})}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Комментарий после НЕверного ответа</label>
                        <MarkdownEditor
                            value={place.settings.error_comment}
                            onChange={(error_comment) => onChange({settings: {...place.settings, error_comment}})}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Женский комментарий после НЕверного ответа</label>
                        <MarkdownEditor
                            value={place.settings.female_error_comment}
                            onChange={(female_error_comment) => onChange({settings: {...place.settings, female_error_comment}})}/>
                    </Form.Field>
                </>
            }
            {
                activePage === PLACE_FORM_TABS.PASSAGE.key &&
                <Form.Input
                    label="Очки ветвления при ошибке"
                    value={place.points_error}
                    onChange={(e) => onChange({points_error: e.target.value})}/>
            }
        </Form>
    )

}

export default QuestionRange