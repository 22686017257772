import React, { useCallback, useEffect, useState } from "react";
import Diagram from "../../components/content/diagram";
import PlaceModal from "./placeModal";
import api from "../../api";
import { useParams } from "react-router-dom";
import { ConnectionModal } from "./connectionModal";

const PageContent = () => {
  const { pageId } = useParams();
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState();
  const [connection, setConnection] = useState();

  const closeModal = () => {
    setSelectedPlace();
    getContent();
  };

  const editPlace = (place) => {
    api.put(`places/${place.id}/`, place);
  };

  const getContent = useCallback(async () => {
    const response = await api.get("places", { page: pageId });
    if (response.ok) {
      setPlaces(await response.json());
    }
  }, [pageId]);

  useEffect(() => {
    getContent();
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        setSelectedPlace();
      }
    };
    const removeSelection = (e) => {
      e.preventDefault();
    };
    window.addEventListener("keyup", escapeHandler);
    const diagramElement = document.getElementById("diagramContainer");
    diagramElement.addEventListener("selectstart", removeSelection);
    return () => {
      window.removeEventListener("keyup", escapeHandler);
      diagramElement.removeEventListener("selectstart", removeSelection);
    };
  }, [getContent]);

  return (
    <div>
      <div id="diagramContainer">
        <Diagram
          selectedPlace={selectedPlace}
          onPlaceChanged={editPlace}
          onSelectConnection={setConnection}
          places={places}
          setPlaces={setPlaces}
          setSelectedPlace={setSelectedPlace}
        />
      </div>
      {selectedPlace && selectedPlace.edit && (
        <PlaceModal
          onChange={(change) =>
            setSelectedPlace({ ...selectedPlace, ...change })
          }
          onClose={closeModal}
          place={selectedPlace}
        />
      )}
      {connection && (
        <ConnectionModal
          connectionData={connection}
          onClose={() => {
            setConnection();
            getContent();
          }}
        />
      )}
    </div>
  );
};

export default PageContent;
