import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import { setCurrentPage } from "../../redux/slices/pageSlice";
import api from "../../api";
import { Loader } from "semantic-ui-react";
import PageContent from "./content";
import PageSettings from "./settings";




const PagesDetails = () => {
    const { pageId } = useParams()
    const dispatch = useDispatch()
    const { currentPage } = useSelector(state => state.page)

    const getPage = useCallback(async () => {
        const response = await api.get(`pages/${pageId}`)
        if (response.ok) {
            dispatch(setCurrentPage(await response.json()))
        }
    }, [dispatch, pageId])

    useEffect(() => {
        if (!currentPage && pageId) {
            
            getPage()
        }
    }, [getPage, currentPage, pageId])

    useEffect(() => {
        return () => dispatch(setCurrentPage(null))
    }, [dispatch])

    return (
        currentPage ?
        <>
            <Routes>
                <Route path="content" element={<PageContent/>}/>
                <Route path="settings" element={<PageSettings onSave={getPage}/>}/>
            </Routes>
        </> : 
        <Loader active/>
    )
}

export default PagesDetails