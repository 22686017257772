import React, { useCallback, useEffect, useState } from "react";
import api from "../../../api";
import { Button, Card, Image } from "semantic-ui-react";
import CharactersForm from "./form";
import { useSelector } from "react-redux";

const CharactersIndex = () => {
  const [characterModalData, setCharacterModalData] = useState(false);
  const [characters, setCharacters] = useState([]);

  const { currentGroup } = useSelector((state) => state.group);

  const getCharacters = useCallback(async () => {
    const response = await api.get("characters", { group: currentGroup.id });
    if (response.ok) {
      setCharacters(await response.json());
    }
  }, [currentGroup]);

  useEffect(() => {
    if (currentGroup) getCharacters();
  }, [currentGroup, getCharacters]);

  return (
    <>
      <Button
        content="Добавить"
        icon="add"
        onClick={() => setCharacterModalData({ isNew: true })}
        basic
      />

      <Card.Group className="marginTop24" itemsPerRow={3}>
        {characters.map((character) => (
          <Card
            onClick={() =>
              setCharacterModalData({ isNew: false, id: character.id })
            }
            link
            key={character.id}
          >
            <Image src={character.avatar} wrapped ui={false} />
            <Card.Content>
              <Card.Header>{`${character.first_name} ${character.last_name}`}</Card.Header>
              <Card.Meta>
                <span className="date">{character.default_role}</span>
              </Card.Meta>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      {characterModalData && (
        <CharactersForm
          character={characterModalData}
          onClose={() => {
            setCharacterModalData(false);
            getCharacters();
          }}
        />
      )}
    </>
  );
};

export default CharactersIndex;
