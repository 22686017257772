import React, { useCallback, useEffect, useState } from "react";
import api from "../api";
import { useParams } from "react-router-dom";
import { Form } from "semantic-ui-react";


const CharactersDropdown = ({ value, onChange, label, type, placeholder }) => {
    const { pageId } = useParams()
    const [places, setPlaces] = useState([])
    const [term, setTerm] = useState("");
    const [opened, setOpened] = useState(false)


    const findPlaces = useCallback(async () => {
        const response = await api.get("places", {page: pageId, type})
        if (response.ok) {
            setPlaces(await response.json())
        }
    }, [pageId, type])

    const getOptions = useCallback(() => {
        let options = opened ? places : places.filter(place => place.id === value)
        options = options.map(place => ({
            key: place.id,
            value: place.id,
            text: place.title,
        }))
        return options
    }, [places, value, opened])

    useEffect(() => {
        findPlaces()
    }, [term, findPlaces])

    return (
        <Form.Dropdown
            fluid
            search
            selection
            value={value}
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            onSearchChange={(e, { searchQuery }) => setTerm(searchQuery)}
            onChange={(e, { value }) => onChange(value)}
            options={getOptions()}
            clearable
            placeholder={placeholder}
            noResultsMessage="Ничего не найдено"
            selectOnBlur={false}
            label={label}/>
    )
     
}

export default CharactersDropdown