import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../api";
import { toast } from "react-toastify";
import { PERMISSION_LEVELS_OPTIONS } from "../../constants";



const PermissionsForm = ({permission, onClose, object_type, object_id}) => {
    const [form, setForm] = useState({
        username: "",
        level: ""
    })

    const deletePermission = async () => {
        const response = await api.delete(`permissions/${permission.id}`)
        if (response.ok) {
            onClose()
        }
    }

    const submit = async () => {
        const data = {
            ...form,
            object_id,
            object_type
        }
        const response = permission.isNew ? await api.post(`permissions/`, data) : await api.put(`permissions/${permission.id}/`, data)
        if (response.ok) {
            toast.success("Права доступа изменены")
            onClose()
        }
    }

    useEffect(() => {
        if (!permission.isNew)
            setForm(permission)
    }, [permission])

    return (
        <Modal open onClose={onClose}>
            <Modal.Header>
                {
                    permission.isNew ? "Добавление прав" : "Редактирование пра доступа"
                }
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths={2}>
                        <Form.Input
                            value={form.username}
                            label="Пользователь"
                            placeholder="Введите пользователя"
                            onChange={(e) => setForm({...form, username: e.target.value})}/>
                        <Form.Dropdown
                            options={PERMISSION_LEVELS_OPTIONS}
                            value={form.level}
                            label="Уровень доступа"
                            selection
                            placeholder="Выберите уровень"
                            onChange={(e, { value }) => setForm({...form, level: value})}/>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div className="betweenFlex">
                    {
                        !permission.isNew ?
                        <Button
                            basic
                            color="red"
                            icon='delete'
                            onClick={deletePermission}
                            content="Удалить"/> : <div></div>
                    }
                <div>
                    <Button 
                        basic 
                        onClick={onClose}
                        content="Закрыть"/>
                    <Button 
                        primary 
                        onClick={submit}
                        content="Сохранить"/>
                </div>
                </div>
            </Modal.Actions>
        </Modal>
    )
}

export default PermissionsForm