import React, { useState } from "react";
import { Form, Menu } from "semantic-ui-react";
import { PLACE_FORM_TABS } from "../../../constants";
import TemplatePlace from "./utils/templatePlace";



const QuestionRange = ({place, onChange}) => {
    const [activePage, setActivePage] = useState(PLACE_FORM_TABS.MAIN.key)
    
    return (
        <Form>
            <TemplatePlace 
                place={place} 
                onChange={onChange} 
                activePage={activePage} 
                setActivePage={setActivePage}
                additionalMenu={
                    <Menu.Item
                        content={PLACE_FORM_TABS.ANSWERS.title}
                        active={activePage === PLACE_FORM_TABS.ANSWERS.key}
                        onClick={() => setActivePage(PLACE_FORM_TABS.ANSWERS.key)}/>
                }/>
            {
                activePage === PLACE_FORM_TABS.MAIN.key &&
                <>
                    <Form.Input
                        label="Заголовок вопроса"
                        value={place.title}
                        placeholder="Введите заголовок"
                        required
                        onChange={(e) => onChange({title: e.target.value})}/>
                    <Form.Input
                        label="Ссылка на вопрос"
                        value={place.settings.question_link}
                        placeholder="Введите ссылку"
                        required
                        onChange={(e) => onChange({settings: {...place.settings, question_link: e.target.value}})}/>
                </>
            }
        </Form>
    )

}

export default QuestionRange