import { useState } from "react";
import { Dropdown } from "semantic-ui-react";

const calculateValue = (value) => {
  try {
    return value.map((role) => ({ text: role, key: role, value: role }));
  } catch {
    return [];
  }
};

export const RolesDropdown = ({ value, onChange }) => {
  const [roles, setRoles] = useState(calculateValue(value));

  const addRole = (e, { value }) => {
    setRoles([...roles, { text: value, value, key: value }]);
    onChange([...roles.map((role) => role.value), value]);
  };

  return (
    <Dropdown
      selection
      multiple
      options={roles}
      value={value || []}
      search
      allowAdditions
      onChange={(e, { value }) => onChange(value)}
      onAddItem={addRole}
    />
  );
};
