import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentLesson: null
}

export const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    setCurrentLesson: (state, action) => {
      state.currentLesson = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentLesson } = lessonSlice.actions
export default lessonSlice.reducer