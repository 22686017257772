import React from "react";
import { Header, Segment } from "semantic-ui-react";
import { PLACES } from "../../constants";



const Elements = ({onMouseDown}) => {

    return (
        <div className="placesContainer">
            {
                Object.entries(PLACES).map(([key, place], idx)  => 
                <Segment onMouseDown={() => onMouseDown({...place, type: key, x: 0, y: 10, isNew: true})} className="placeItem" key={key}>
                    <Header
                        icon={place.icon}
                        subheader={key}
                        content={place.title}/>
                </Segment>)
            }
        </div>
    )
}

export default Elements