import React from "react";
import { Menu } from "semantic-ui-react";
import { PLACE_FORM_TABS } from "../../../../constants";
import PassagePage from "./passagePage";
import ScriptsPage from "./scriptsPage";



const TemplatePlace = ({ activePage, setActivePage, place, onChange, additionalMenu}) => {

    return (
        <>
            <Menu pointing secondary>
                <Menu.Item
                    content={PLACE_FORM_TABS.MAIN.title}
                    active={PLACE_FORM_TABS.MAIN.key === activePage}
                    onClick={() => setActivePage(PLACE_FORM_TABS.MAIN.key)}/>
                {
                    additionalMenu
                }
                <Menu.Item
                    content={PLACE_FORM_TABS.SCRIPTS.title}
                    active={PLACE_FORM_TABS.SCRIPTS.key === activePage}
                    onClick={() => setActivePage(PLACE_FORM_TABS.SCRIPTS.key)}/>
                <Menu.Item
                    content={PLACE_FORM_TABS.PASSAGE.title}
                    active={PLACE_FORM_TABS.PASSAGE.key === activePage}
                    onClick={() => setActivePage(PLACE_FORM_TABS.PASSAGE.key)}/>
            </Menu>
            {
                activePage === PLACE_FORM_TABS.PASSAGE.key &&
                <PassagePage place={place} onChange={onChange}/>
            }
            {
                activePage === PLACE_FORM_TABS.SCRIPTS.key &&
                <ScriptsPage place={place} onChange={onChange}/>
            }
        </>
    )

}

export default TemplatePlace