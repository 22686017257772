import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Menu, Popup } from "semantic-ui-react";
import api from "../../api";
import { useSelector } from "react-redux";
import { SETTINGS_PAGES } from "../../constants";
import PermissionsIndex from "../permissions";
import { MarkdownEditor } from "../../components";
var FileSaver = require("file-saver");

const LessonSettings = ({ onSave }) => {
  const navigate = useNavigate();
  const { simulatorId } = useParams();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState("main");

  const { currentLesson: lesson } = useSelector((state) => state.lesson);

  const submit = async () => {
    const body = { ...form };
    delete body["picture"];
    const response = await api.put(`lessons/${form.id}/`, body);
    if (response.ok) {
      toast.success("Урок изменен");
      onSave();
    } else {
      const data = await response.json();
      setErrors(data || {});
    }
  };

  const exportLesson = useCallback(async () => {
    const response = await api.post("export/", {
      type: "lesson",
      id: lesson.id,
    });
    if (response.ok) {
      FileSaver.saveAs(
        await response.blob(),
        `lesson_${lesson.name}_${lesson.id}.json`
      );
    }
  }, [lesson]);

  const deleteLesson = async () => {
    const response = await api.delete(`lessons/${form.id}/`);
    if (response.ok) {
      navigate(`/simulators/${simulatorId}/lessons`);
    }
  };

  useEffect(() => {
    setForm({ ...lesson, settings: lesson.settings || {} });
  }, [lesson]);

  return (
    <>
      <Menu secondary pointing widths={2}>
        {Object.keys(SETTINGS_PAGES).map((key) => (
          <Menu.Item
            key={key}
            content={SETTINGS_PAGES[key]}
            active={page === key}
            onClick={() => setPage(key)}
          />
        ))}
      </Menu>
      {form.id && page === "main" && (
        <Form>
          <Form.Input
            label="Название"
            required
            placeholder="Введите название"
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            error={errors.name}
            value={form.name}
          />
          <Form.Field>
            <label>Описание</label>
            <MarkdownEditor
              value={form.description}
              onChange={(description) => setForm({ ...form, description })}
            />
          </Form.Field>
          <Form.Input
            label="Количество бесплатных блоков"
            type="number"
            placeholder="Введите количество блоков"
            onChange={(e) =>
              setForm({
                ...form,
                amount_places_without_subscription: e.target.value,
              })
            }
            value={form.amount_places_without_subscription}
          />
          <Form.Group>
            <Form.Checkbox
              label="Активный"
              onChange={(e, { checked }) =>
                setForm({ ...form, active: checked })
              }
              checked={form.active}
            />
          </Form.Group>
          <div className="betweenFlex">
            <div>
              <Popup
                on={"click"}
                content={
                  <Button
                    color="red"
                    icon="trash"
                    content="Точно удалить"
                    onClick={deleteLesson}
                  />
                }
                trigger={
                  <Button basic color="red" icon="delete" content="Удалить" />
                }
              />
              <Button
                basic
                icon="download"
                content="Скачать"
                onClick={exportLesson}
              />
            </div>
            <Button primary onClick={submit} content="Сохранить" />
          </div>
        </Form>
      )}
      {form.id && page === "permissions" && <PermissionsIndex />}
    </>
  );
};

export default LessonSettings;
