import queryString from "query-string";
import { store } from "./redux/store";
import { setAuthInfo } from "./redux/slices/authSlice";
import { toast } from "react-toastify";

export const AUTH_TOKEN_KEY = "authInfo";
export const DEFAULT_API_PATH = process.env.REACT_APP_DEFAULT_API_PATH;

class Api {
  async get(path, params = {}, options) {
    path = `${DEFAULT_API_PATH}/${path}?${queryString.stringify(
      { ...params, format: "json" },
      { arrayFormat: "comma" }
    )}`;
    return this.handleResponse(
      fetch(path, {
        headers: await this.headers(),
        ...options,
      })
    );
  }

  post(path, body, multipart) {
    return this.request(path, body, "POST", multipart);
  }

  put(path, body, multipart) {
    return this.request(path, body, "PUT", multipart);
  }

  patch(path, body, multipart) {
    return this.request(path, body, "PATCH", multipart);
  }

  delete(path, body) {
    return this.request(path, body, "DELETE");
  }

  request(path, body = {}, method, multipart) {
    return this.handleResponse(this.fetch(path, body, method, multipart));
  }

  async handleResponse(request) {
    const response = await request;
    if (response.status === 401) {
      store.dispatch(setAuthInfo(null));
      localStorage.removeItem("authInfo");
    } else if (response.status === 403) {
      toast.error("У вас нет прав");
    }
    return response;
  }

  async fetch(path, body = {}, method, multipart) {
    let headers = await this.headers(multipart);
    let data = JSON.stringify(body);
    if (multipart) {
      data = new FormData();
      for (var key in body) {
        data.append(key, body[key]);
      }
      delete headers["Content-Type"];
    }
    return fetch(`${DEFAULT_API_PATH}/${path}`, {
      method: method,
      headers: headers,
      body: data,
    });
  }

  async headers(multipart) {
    let _headers = {
      "Content-Type": !multipart ? "application/json" : "multipart/form-data",
    };
    let token = this.getToken();
    if (token) _headers["Authorization"] = `Bearer ${token}`;
    return _headers;
  }

  getToken() {
    const authData = store.getState().auth.authInfo;
    return authData && authData.access_token;
  }
}
const api = new Api();
export default api;
