import React, { useCallback, useEffect, useState } from "react";
import api from "../api";
import { useParams } from "react-router-dom";
import { Form } from "semantic-ui-react";


const PagesDropdown = ({ value, onChange, label }) => {
    const { lessonId } = useParams()
    const [pages, setPages] = useState([])
    const [term, setTerm] = useState("");
    const [opened, setOpened] = useState(false)


    const findPages = useCallback(async () => {
        const response = await api.get("pages", {lesson: lessonId})
        if (response.ok) {
            setPages(await response.json())
        }
    }, [lessonId])

    const getOptions = useCallback(() => {
        let options = opened ? pages : pages.filter(page => page.id === value)
        options = options.map(page => ({
            key: page.id,
            value: page.id,
            text: page.name
        }))
        return options
    }, [pages, value, opened])

    useEffect(() => {
        findPages()
    }, [term, findPages])

    return (
        <Form.Dropdown
            fluid
            search
            selection
            value={value}
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            onSearchChange={(e, { searchQuery }) => setTerm(searchQuery)}
            onChange={(e, { value }) => onChange(value)}
            options={getOptions()}
            placeholder="Начните вводить"
            noResultsMessage="Ничего не найдено"
            selectOnBlur={false}
            label={label}/>
    )
     
}

export default PagesDropdown