import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import { setCurrentPage } from "../../../redux/slices/pageSlice";
import { Button, Card } from "semantic-ui-react";
import PagesForm from "./form";


const PagesIndex = () => {
    const [addModal, setAddModal] = useState(false)
    const [pages, setPages] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { currentLesson } = useSelector(state => state.lesson)


    const pageDetails = (page) => {
        dispatch(setCurrentPage(page))
        navigate(`${page.id}/content`)
    }

    const getPages = useCallback(async () => {
        const response = await api.get("pages", {lesson: currentLesson.id})
        if (response.ok) {
            setPages(await response.json())
        }
    }, [currentLesson.id])

    useEffect(() => {
        if (currentLesson)
            getPages()
    }, [currentLesson, getPages])

    return (
        <>
            <Button 
                content="Добавить страницу" 
                icon="add" 
                onClick={() => setAddModal(true)}
                basic/>
            <Card.Group className="marginTop24" itemsPerRow={3}>
                {
                    pages.map(page => 
                        <Card link onClick={() => pageDetails(page)} key={page.id}>
                            <Card.Content>
                                <Card.Header>{page.name}</Card.Header>
                            <Card.Meta>
                                <span className='date'>{page.slug}</span>
                            </Card.Meta>
                            <Card.Description>
                                {page.description}
                            </Card.Description>
                            </Card.Content>
                        </Card>)
                }
            </Card.Group>
            {
                addModal &&
                <PagesForm onClose={() => {setAddModal(false); getPages()}}/>
            }
        </>
    )

}

export default PagesIndex