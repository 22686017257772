import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { MarkdownEditor, TheoryChaptersDropdown } from "../../";
import { PLACE_FORM_TABS } from "../../../constants";
import TemplatePlace from "./utils/templatePlace";




const Theory = ({place, onChange}) => {
    const [activePage, setActivePage] = useState(PLACE_FORM_TABS.MAIN.key)
    
    return (
        <Form>
            <TemplatePlace place={place} onChange={onChange} activePage={activePage} setActivePage={setActivePage}/>
            {
                activePage === PLACE_FORM_TABS.MAIN.key &&
                <>
                    <Form.Input
                        label="Заголовок вопроса"
                        value={place.title}
                        placeholder="Введите заголовок"
                        required
                        onChange={(e) => onChange({title: e.target.value})}/>
                    <Form.Field required>
                        <label>Глава теории</label>
                        <TheoryChaptersDropdown
                            value={place.theory_chapter}
                            onChange={(theory_chapter) => onChange({theory_chapter})}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Текст теории</label>
                        <MarkdownEditor
                            value={place.settings.text}
                            onChange={(text) => onChange({settings: {...place.settings, text}})}/>
                    </Form.Field>
                </>
            }
        </Form>
    )

}

export default Theory