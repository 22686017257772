import React from "react";
import { Form } from "semantic-ui-react";


const PassagePage = ({place, onChange}) => {

    return (
        <>
            <Form.Input
                type="number"
                value={place.award}
                label="Награда за прохождение"
                placeholder="Введите награду"
                onChange={(e) => onChange({award: e.target.value})}/>
            <Form.Input
                type="number"
                value={place.points}
                label="Очки ветвления"
                placeholder="Введите очки за ветвление"
                onChange={(e) => onChange({points: e.target.value})}/>
            <Form.Checkbox
                checked={place.is_start}
                label="Начало страницы"
                onChange={(e, { checked }) => onChange({is_start: checked})}/>
        </>
    )

}

export default PassagePage