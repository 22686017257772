import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import { setCurrentLesson } from "../../redux/slices/lessonSlice";
import api from "../../api";
import { Loader } from "semantic-ui-react";
import PagesIndex from "./pages";
import PagesDetails from "../pages";
import LessonSettings from "./settings";



const LessonsDetails = () => {
    const { lessonId } = useParams()
    const dispatch = useDispatch()
    const { currentLesson } = useSelector(state => state.lesson)

    const getLesson = useCallback(async () => {
        const response = await api.get(`lessons/${lessonId}`)
        if (response.ok) {
            dispatch(setCurrentLesson(await response.json()))
        }
    }, [dispatch, lessonId])

    useEffect(() => {
        if (!currentLesson && lessonId) {
            getLesson()
        }
    }, [getLesson, currentLesson, lessonId])

    useEffect(() => {
        return () => dispatch(setCurrentLesson(null))
    }, [dispatch])
    
    return (
        currentLesson ?
        <>
            <Routes>
                <Route path="pages" element={<PagesIndex/>}/>
                <Route path="pages/:pageId/*" element={<PagesDetails/>}/>
                <Route path="settings" element={<LessonSettings onSave={getLesson}/>}/>
            </Routes>
        </> : 
        <Loader active/>
    )
}

export default LessonsDetails