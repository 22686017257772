import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'


const BreadcrumbExampleSize = () => {

    const { currentSimulator } = useSelector(state => state.simulator)
    const { currentLesson } = useSelector(state => state.lesson)
    const { currentPage } = useSelector(state => state.page)


    return <Breadcrumb size={'large'}>
        {
            currentSimulator &&
            <>
                <Breadcrumb.Section 
                    link 
                    as={Link}
                    to={`/simulators`}>
                    Симуляторы
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section 
                    link 
                    as={Link}
                    to={`/simulators/${currentSimulator.id}/lessons`}
                    active={!currentLesson}>
                    Симулятор {currentSimulator.name}
                </Breadcrumb.Section>
                {
                    currentLesson &&
                    <Breadcrumb.Divider icon='right chevron' />
                }
            </>
        }
        {
            currentLesson &&
            <>
                <Breadcrumb.Section 
                    link 
                    as={Link}
                    to={`/simulators/${currentSimulator.id}/lessons/${currentLesson.id}/pages`}
                    active={!currentPage}>
                    Урок {currentLesson.name}
                </Breadcrumb.Section>
                {
                    currentPage &&
                    <Breadcrumb.Divider icon='right chevron' />
                }
            </>
        }
        {
            currentPage &&
            <>
                <Breadcrumb.Section 
                    link 
                    as={Link}
                    to={`/simulators/${currentSimulator.id}/lessons/${currentLesson.id}/pages/${currentPage.id}/content`}
                    active>
                    Страница {currentPage.name}
                </Breadcrumb.Section>
            </>
        }
    </Breadcrumb>
}

export default BreadcrumbExampleSize