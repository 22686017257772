import { configureStore } from '@reduxjs/toolkit'
import groupReducer from './slices/groupSlice'
import simulatorReducer from './slices/simulatorSlice'
import lessonReducer from './slices/lessonSlice'
import pageReducer from './slices/pageSlice'
import authReducer from './slices/authSlice'

export const store = configureStore({
  reducer: {
    group: groupReducer,
    simulator: simulatorReducer,
    lesson: lessonReducer,
    page: pageReducer,
    auth: authReducer
  },
})