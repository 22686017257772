import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { PLACE_TYPES } from "../../constants";
import { Theory, Text, Message, Question, OpenQuestion, OpenQuestionExpert, OpenQuestionCheck, QuestionUserChoice, QuestionRange, QuestionExternal, AiOpenQuestion } from "../../components/content/places";
import api from "../../api";
import { useParams } from "react-router-dom";


const PLACE_FORMS = {
    [PLACE_TYPES.theory]: Theory,
    [PLACE_TYPES.safetext]: Text,
    [PLACE_TYPES.message]: Message,
    [PLACE_TYPES.question]: Question,
    [PLACE_TYPES.openquestion]: OpenQuestion,
    [PLACE_TYPES.openquestionexpert]: OpenQuestionExpert,
    [PLACE_TYPES.questionanswercheck]: OpenQuestionCheck,
    [PLACE_TYPES.questionuserchoice]: QuestionUserChoice,
    [PLACE_TYPES.questionrange]: QuestionRange,
    [PLACE_TYPES.questionexternal]: QuestionExternal,
    [PLACE_TYPES.aiopenquestion]: AiOpenQuestion,
}
const PlaceModal = ({ place, onChange, onClose }) => {
    const { pageId } = useParams()


    const submit = async () => {
        const response = place.id ? await api.put(`places/${place.id}/`, {...place, page: pageId}) : await api.post("places/", {...place, page: pageId})
        if (response.ok) {
            onClose()
        }
    }

    const deletePlace = async () => {
        const response = await api.delete(`places/${place.id}/`)
        if (response.ok) {
            onClose()
        }
    }

    const PlaceComponent = PLACE_FORMS[place.type]

    return (
        <Modal centered={false} open size="large" onClose={onClose}>
            <Modal.Header>
                {
                    place.id ? `Редактирование ${place.title}` : `Добавление ${place.title}`
                }
            </Modal.Header>
            <Modal.Content scrolling>
                <PlaceComponent place={place} onChange={onChange}/>
            </Modal.Content>
            <Modal.Actions>
                <div className="betweenFlex">
                        {
                            !place.isNew ?
                            <Button
                                basic
                                color="red"
                                icon='delete'
                                onClick={deletePlace}
                                content="Удалить"/> : <div></div>
                        }
                    <div>
                        <Button 
                            basic 
                            icon="close"
                            onClick={onClose}
                            content="Закрыть"/>
                        <Button 
                            primary 
                            icon="save"
                            onClick={submit}
                            content="Сохранить"/>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    )
}

export default PlaceModal