import React, { useState } from "react";
import { Button, Card, Form, Menu, Modal } from "semantic-ui-react";
import { MarkdownEditor } from "../../";
import { PLACE_FORM_TABS } from "../../../constants";
import TemplatePlace from "./utils/templatePlace";


const DEFAULT_ANSWER = {
    text: "",
    female_text: "",
    comment: "",
    female_comment: "",
    points: 0,
    is_right: false
}

const AnswersBlock = ({ answers, onChange }) => {
    const [answerModal, setAnswerModal] = useState()

    const submitAnswerModal = () => {
        if (answerModal.isNew) {
            onChange({answer: [...answers, answerModal.answer]})
        } else {
            onChange({answer: answers.map((ans, idx) => answerModal.idx === idx ? {...answerModal.answer} : ans)})
        }
        setAnswerModal()
    }

    const deleteAnswer = () => {

        onChange({answer: answers.filter((ans, idx) => answerModal.idx !== idx)})
        setAnswerModal()
    }


    return (
        <>
            <br/>
            <br/>
            <Card.Group itemsPerRow={3} className="marginBottom24">
                {
                    answers.map((answer, idx) => 
                    <Card link onClick={() => setAnswerModal({answer, idx})} key={`answer_${idx}`} color={answer.is_right ? "green" : "red"}>
                        <Card.Content>
                            <Card.Header>{answer.text}</Card.Header>
                            <Card.Meta>{answer.points}</Card.Meta>
                            <Card.Description>{answer.comment}</Card.Description>
                        </Card.Content>
                    </Card>
                    )
                }
            </Card.Group>
            <Button onClick={() => setAnswerModal({answer: DEFAULT_ANSWER, isNew: true})} basic content="Добавить ответ" icon="add" primary/>
            {
                answerModal &&
                <Modal centered={false} size="large" open onClose={() => setAnswerModal()}>
                    <Modal.Header>Добавление ответа</Modal.Header>
                    <Modal.Content scrolling>
                        <Form>
                            <Form.Input
                                required
                                label="Текст ответа"
                                placeholder="Введите текст"
                                value={answerModal.answer.text}
                                onChange={(e) => setAnswerModal({...answerModal, answer: {...answerModal.answer, text: e.target.value}})}/>
                            <Form.Input
                                required
                                label="Текст ответа для женского рода"
                                placeholder="Введите текст"
                                value={answerModal.answer.female_text}
                                onChange={(e) => setAnswerModal({...answerModal, answer: {...answerModal.answer, female_text: e.target.value}})}/>
                            <Form.Field required>
                                <label>Текст если выбран</label>
                                <MarkdownEditor
                                    value={answerModal.answer.comment}
                                    onChange={(comment) => setAnswerModal({...answerModal, answer: {...answerModal.answer, comment}})}/>
                            </Form.Field>
                            <Form.Field required>
                                <label>Женский текст если выбран</label>
                                <MarkdownEditor
                                    value={answerModal.answer.female_comment}
                                    onChange={(female_comment) => setAnswerModal({...answerModal, answer: {...answerModal.answer, female_comment}})}/>
                            </Form.Field>
                            <Form.Input
                                type="number"
                                label="Очков ветвления за выбор"
                                value={answerModal.answer.points}
                                onChange={(e) => setAnswerModal({...answerModal, answer: {...answerModal.answer, points: e.target.value}})}/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions className="flexBetweenBlock">
                        <div>
                            {
                                answerModal.isNew ? <div></div> : 
                                <Button onClick={deleteAnswer} color="red" basic content="Удалить" icon="trash" />
                            }
                        </div>
                        <div>
                            <Button 
                                content="Закрыть" 
                                basic 
                                icon="close"
                                onClick={() => setAnswerModal()}/>
                            <Button 
                                content="Сохранить"
                                primary
                                icon="close"
                                onClick={submitAnswerModal}/>
                        </div>
                    </Modal.Actions>
                </Modal>
            }
        </>
    )
}


const QuestionUserChoice = ({place, onChange}) => {
    const [activePage, setActivePage] = useState(PLACE_FORM_TABS.MAIN.key)
    
    return (
        <Form>
            <TemplatePlace 
                place={place} 
                onChange={onChange} 
                activePage={activePage} 
                setActivePage={setActivePage}
                additionalMenu={
                    <Menu.Item
                        content={PLACE_FORM_TABS.ANSWERS.title}
                        active={activePage === PLACE_FORM_TABS.ANSWERS.key}
                        onClick={() => setActivePage(PLACE_FORM_TABS.ANSWERS.key)}/>
                }/>
            {
                activePage === PLACE_FORM_TABS.MAIN.key &&
                <>
                    <AnswersBlock answers={place.answer || []} onChange={onChange}/>
                </>
            }
        </Form>
    )

}

export default QuestionUserChoice