import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  authInfo: JSON.parse(localStorage.getItem("authInfo"))
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthInfo: (state, action) => {
      state.authInfo = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAuthInfo } = authSlice.actions
export default authSlice.reducer
