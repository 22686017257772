import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../../api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";


const CharactersForm = ({theory, onClose}) => {
    const { simulatorId } = useParams()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        name: ""
    })
    const [errors, setErrors] = useState({})

    const deleteTheory = async () => {
        const response = await api.delete(`theory_chapters/${theory.id}`)
        if (response.ok) {
            onClose()
            toast.success("Глава теории удалена")
        }
    }

    const submit = useCallback(async () => {
        setLoading(true)
        const response = theory.isNew ? 
         await api.post("theory_chapters/", {...form, simulator: simulatorId}, true) :
         await api.put(`theory_chapters/${theory.id}`, form)

        if (response.ok) {
            toast.success(theory.isNew ? "Глава теории добавлена" : "Глава теории изменена")
            onClose()
        } else {
            setErrors(await response.json())
        }
        setLoading(false)
    }, [form, simulatorId, onClose, theory])

    useEffect(() => {
        if (!theory.isNew) {
            const getTheory = async () => {
                const response = await api.get(`theory_chapters/${theory.id}`)
                if (response.ok) {
                    setForm(await response.json())
                }
            }
            getTheory()
        }
    }, [theory])


    return (
        <Modal open onClose={onClose}>
            <Modal.Header>{theory.isNew ? "Добавление теории" : "Редактирование теории"}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        required
                        value={form.name}
                        onChange={(e) => setForm({...form, name: e.target.value})}
                        label="Название"
                        placeholder="Введите название"
                        error={errors.name}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div className="betweenFlex">
                    {
                        !theory.isNew ?
                        <Button
                            basic
                            color="red"
                            icon='delete'
                            onClick={deleteTheory}
                            content="Удалить"/> : <div></div>
                    }
                <div>
                    <Button 
                        basic 
                        onClick={onClose}
                        content="Закрыть"/>
                    <Button 
                        primary 
                        loading={loading}
                        disabled={loading}
                        onClick={submit}
                        content="Сохранить"/>
                </div>
                </div>
            </Modal.Actions>
        </Modal>
    )
}

export default CharactersForm