import {Button, Card} from "semantic-ui-react";
import React from "react";
import api from "../api";

const TagCard = ({tag, onDelete}) => {

  const deleteTag = (tag_id) => async () => {
        await api.delete(`tags/${tag_id}`)
        onDelete()
  }
  return (
    <>
      <Card key={tag.id} className="tag__card">
        <Card.Content className="tag__wrapper betweenFlex">
            <div className="tag__title">{tag.name}</div>
            <Button
                className="tag__delete"
                negative
                basic
                size="tiny"
                icon="trash alternate outline"
                onClick={deleteTag(tag.id)}
            />
        </Card.Content>
      </Card>
    </>
  )
}

export default TagCard