import React, { useCallback, useEffect, useState } from "react";
import api from "../api";
import { useParams } from "react-router-dom";
import { Form } from "semantic-ui-react";


const CharactersDropdown = ({ value, onChange, label }) => {
    const { simulatorId } = useParams()
    const [characters, setCharacters] = useState([])
    const [term, setTerm] = useState("");
    const [opened, setOpened] = useState(false)


    const findCharacters = useCallback(async () => {
        const response = await api.get("characters", {simulator: simulatorId})
        if (response.ok) {
            setCharacters(await response.json())
        }
    }, [simulatorId])

    const getOptions = useCallback(() => {
        let options = opened ? characters : characters.filter(char => char.id === value)
        options = options.map(char => ({
            key: char.id,
            value: char.id,
            text: `${char.first_name} ${char.last_name}`,
            description: char.default_role
        }))
        return options
    }, [characters, value, opened])

    useEffect(() => {
        findCharacters()
    }, [term, findCharacters])

    return (
        <Form.Dropdown
            fluid
            search
            selection
            value={value}
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            onSearchChange={(e, { searchQuery }) => setTerm(searchQuery)}
            onChange={(e, { value }) => onChange(value)}
            options={getOptions()}
            clearable
            placeholder="Без персонажа"
            noResultsMessage="Ничего не найдено"
            selectOnBlur={false}
            label={label}/>
    )
     
}

export default CharactersDropdown