import React from "react";
import { Form } from "semantic-ui-react";


const ScriptsPage = ({place, onChange}) => {

    return (
        <>
            <Form.TextArea
                value={place.settings.script}
                label="Скрипт"
                placeholder="Введите скрипт"
                onChange={(e) => onChange({settings: {...place.settings, script: e.target.value}})}/>
            <Form.Input
                value={place.settings.script_id}
                label="ID скрипт"
                placeholder="Введите id скрипта"
                onChange={(e) => onChange({settings: {...place.settings, script_id: e.target.value}})}/>
        </>
    )

}

export default ScriptsPage