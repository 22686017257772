import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar";
import * as Views from "./views";
import { useCallback, useEffect, useState } from "react";
import api from "./api";
import { useDispatch } from "react-redux";
import { setGroups } from "./redux/slices/groupSlice";
import { Loader } from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const [appLoaded, setAppLoaded] = useState(false);

  const { authInfo } = useSelector((state) => state.auth);

  const getGroups = useCallback(async () => {
    const createNewGroup = async () => {
      const response = await api.post("simulators_group/");
      if (response.ok) {
        getGroups();
      }
    };

    const response = await api.get("simulators_group");
    if (response.ok) {
      const data = await response.json();
      if (data.length > 0) {
        setAppLoaded(true);
        dispatch(setGroups(data));
      } else createNewGroup();
    }
  }, [dispatch]);

  useEffect(() => {
    if (authInfo) getGroups();
  }, [getGroups, authInfo]);

  return (
    <Router>
      {appLoaded ? (
        <div className="appContent">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content" style={{ paddingRight: "32px" }}>
            <Routes>
              <Route path="/*" element={<Views.GroupDetails />} />
              <Route
                path="/simulators/:simulatorId/*"
                element={<Views.SimulatorDetails />}
              />
              <Route path="/settings" element={<Views.GroupSettings />} />
            </Routes>
          </div>
        </div>
      ) : authInfo ? (
        <Loader active />
      ) : (
        <Routes>
          <Route path="*" element={<Views.AuthView />} />
        </Routes>
      )}
      <ToastContainer />
    </Router>
  );
}

export default App;
