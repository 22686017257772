import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import SimulatorsIndex from "../simulators";
import CharactersIndex from "./characters";
import TheoriesIndex from "./theories";

export const GroupDetails = () => {
  const { currentGroup } = useSelector((state) => state.group);

  console.log("MEM");
  return currentGroup ? (
    <>
      <Routes>
        <Route path="/" element={<SimulatorsIndex />} />
        <Route path="/simulators/*" element={<SimulatorsIndex />} />
        <Route path="characters" element={<CharactersIndex />} />
        <Route path="theory" element={<TheoriesIndex />} />
      </Routes>
    </>
  ) : (
    <Loader active />
  );
};
