import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Header, Label, Table } from "semantic-ui-react";
import api from "../../api";
import { PERMISSION_LEVELS } from "../../constants";
import PermissionsForm from './form'


const MODES_DATA = {
    page: {
        title: "странице"
    },
    simulator: {
        title: "симулятору"
    },
    lesson: {
        title: "уроку"
    }
}

const PermissionsIndex = () => {
    const [permissions, setPermissions] = useState([])
    const [editPermission, setEditPermission] = useState()

    const { currentSimulator } = useSelector(state => state.simulator)
    const { currentLesson } = useSelector(state => state.lesson)
    const { currentPage } = useSelector(state => state.page)

    const mode = useMemo(() => {
        return currentPage ? "page" : currentLesson ? "lesson" : "simulator" 
    }, [currentLesson, currentPage])

    const object = useMemo(() => {
        return currentPage || currentLesson || currentSimulator
    }, [currentLesson, currentPage, currentSimulator])

    const getPermissions = useCallback(async () => {
        const response = await api.get("permissions", {object_id: object.id, content_type: mode})
        if (response.ok) {
            setPermissions(await response.json())
        }
    }, [object, mode])

    useEffect(() => {
        getPermissions()
    }, [getPermissions])

    return (
        <>
            <Header
                content={`Права доступа к ${MODES_DATA[mode].title}`}
                subheader={"Редактирование доступа"}/>
            <Button
                basic
                content="Добавить"
                icon='add'
                onClick={() => setEditPermission({isNew: true})}/>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Пользователь
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Уровень доступа
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        permissions.map((permission) => 
                        <Table.Row key={permission.id}>
                            <Table.Cell>
                                <Label
                                    as={'a'}
                                    onClick={() => setEditPermission(permission)}
                                    content={permission.username}/>
                            </Table.Cell>
                            <Table.Cell>{PERMISSION_LEVELS[permission.level]}</Table.Cell>
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>
            {
                editPermission &&
                <PermissionsForm 
                    permission={editPermission} 
                    object_id={object.id}
                    object_type={mode}
                    onClose={() => {setEditPermission(); getPermissions()}}/>
            }
        </>
    )
}

export default PermissionsIndex