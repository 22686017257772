import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Form, Header, Modal, Popup } from "semantic-ui-react";
import api from "../../api";
import { useSelector } from "react-redux";
import { PagesDropdown } from "../../components";
import PermissionsIndex from "../permissions";
var FileSaver = require("file-saver");

const initialLinkModal = {
  points: 0,
  start_page: null,
  end_page: null,
  isNew: true,
};

const PageSettings = ({ onSave }) => {
  const navigate = useNavigate();
  const { lessonId, simulatorId } = useParams();
  const [linkModal, setLinkModal] = useState();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const { currentPage: page } = useSelector((state) => state.page);

  const submit = async () => {
    const body = { ...form };
    delete body["picture"];
    const response = await api.put(`pages/${form.id}/`, body);
    if (response.ok) {
      toast.success("Страница изменена");
      onSave();
    } else {
      const data = await response.json();
      setErrors(data || {});
    }
  };

  const deleteLesson = async () => {
    const response = await api.delete(`pages/${form.id}/`);
    if (response.ok) {
      navigate(`/simulators/${simulatorId}/lessons/${lessonId}/pages`);
    }
  };

  const exportPage = useCallback(async () => {
    const response = await api.post("export/", { type: "page", id: page.id });
    if (response.ok) {
      FileSaver.saveAs(
        await response.blob(),
        `page_${page.name}_${page.id}.json`
      );
    }
  }, [page]);

  const deleteLink = async () => {
    const response = await api.delete(`pages_links/${linkModal.id}/`);
    if (response.ok) {
      setLinkModal();
      onSave();
    }
  };

  const submitLink = async () => {
    const response = linkModal.isNew
      ? await api.post(`pages_links/`, { ...linkModal, start_page: page.id })
      : await api.put(`pages_links/${linkModal.id}/`, linkModal);
    if (response.ok) {
      setLinkModal();
      onSave();
    }
  };

  useEffect(() => {
    setForm({ ...page, settings: page.settings || {} });
  }, [page]);

  return (
    <>
      {form.id && (
        <Form>
          <Form.Input
            label="Название"
            required
            placeholder="Введите название"
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            error={errors.name}
            value={form.name}
          />
          <Header
            content="Переходы"
            subheader="Переходы на другие страницы"
            dividing
            icon={"linkify"}
          />
          <Card.Group itemsPerRow={3}>
            {page.next_pages.map((link) => (
              <Card key={link.id} link onClick={() => setLinkModal(link)}>
                <Card.Content>
                  <Card.Header>{link.end_page_name}</Card.Header>
                  <Card.Meta>{link.points}</Card.Meta>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
          <Button
            className="marginTop24"
            icon="add"
            onClick={() => setLinkModal(initialLinkModal)}
            basic
            primary
            content="Добавить переход"
          />
          <PermissionsIndex />
          <div className="betweenFlex marginTop24">
            <div>
              <Popup
                on={"click"}
                content={
                  <Button
                    color="red"
                    icon="trash"
                    content="Точно удалить"
                    onClick={deleteLesson}
                  />
                }
                trigger={
                  <Button basic color="red" icon="delete" content="Удалить" />
                }
              />
              <Button
                basic
                icon="download"
                content="Скачать"
                onClick={exportPage}
              />
            </div>
            <Button primary onClick={submit} content="Сохранить" />
          </div>
        </Form>
      )}
      {linkModal && (
        <Modal open onClose={() => setLinkModal()}>
          <Modal.Header>Добавление перехода</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Страница</label>
                <PagesDropdown
                  value={linkModal.end_page}
                  onChange={(end_page) =>
                    setLinkModal({ ...linkModal, end_page })
                  }
                />
              </Form.Field>
              <Form.Input
                label="Количество очков"
                value={linkModal.points}
                placeholder="Введите очки"
                onChange={(e) =>
                  setLinkModal({ ...linkModal, points: e.target.value })
                }
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <div className="betweenFlex">
              {!linkModal.isNew ? (
                <Button
                  basic
                  color="red"
                  icon="delete"
                  onClick={deleteLink}
                  content="Удалить"
                />
              ) : (
                <div></div>
              )}
              <div>
                <Button
                  basic
                  onClick={() => setLinkModal()}
                  content="Закрыть"
                />
                <Button primary onClick={submitLink} content="Сохранить" />
              </div>
            </div>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default PageSettings;
