import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CharactersForm = ({ character, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    default_role: "",
    is_user: false,
    male: false,
    avatar: null,
  });
  const [errors, setErrors] = useState({});

  const { currentGroup } = useSelector((state) => state.group);

  const deleteCharacter = async () => {
    const response = await api.delete(`characters/${character.id}`);
    if (response.ok) {
      onClose();
      toast.success("Персонаж удален");
    }
  };

  const submit = useCallback(async () => {
    setLoading(true);
    const response = character.isNew
      ? await api.post("characters/", { ...form, group: currentGroup.id }, true)
      : await api.put(`characters/${character.id}`, form);

    if (response.ok) {
      toast.success(character.isNew ? "Персонаж добавлен" : "Персонаж изменен");
      onClose();
    } else {
      setErrors(await response.json());
    }
    setLoading(false);
  }, [form, onClose, character, currentGroup]);

  useEffect(() => {
    if (!character.isNew) {
      const getCharacter = async () => {
        const response = await api.get(`characters/${character.id}`);
        if (response.ok) {
          setForm(await response.json());
        }
      };
      getCharacter();
    }
  }, [character]);

  return (
    <Modal open onClose={onClose}>
      <Modal.Header>Добавление персонажа</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            required
            label="Аватар"
            errors={errors.avatar}
            onChange={(e) => setForm({ ...form, avatar: e.target.files[0] })}
            type="file"
          />
          <Form.Group widths={3}>
            <Form.Input
              value={form.first_name}
              onChange={(e) => setForm({ ...form, first_name: e.target.value })}
              label="Имя"
              placeholder="Введите имя"
            />
            <Form.Input
              value={form.last_name}
              onChange={(e) => setForm({ ...form, last_name: e.target.value })}
              label="Фамилия"
              placeholder="Введите фамилию"
            />
            <Form.Input
              value={form.default_role}
              onChange={(e) =>
                setForm({ ...form, default_role: e.target.value })
              }
              label="Роль"
              placeholder="Введите роль"
            />
          </Form.Group>
          <Form.Checkbox
            checked={form.is_user}
            onChange={(e, { checked }) =>
              setForm({ ...form, is_user: checked })
            }
            label="Персонаж пользователя"
          />
          <Form.Checkbox
            checked={form.male}
            onChange={(e, { checked }) => setForm({ ...form, male: checked })}
            label="Персонаж женского поля"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <div className="betweenFlex">
          {!character.isNew ? (
            <Button
              basic
              color="red"
              icon="delete"
              onClick={deleteCharacter}
              content="Удалить"
            />
          ) : (
            <div></div>
          )}
          <div>
            <Button basic onClick={onClose} content="Закрыть" />
            <Button
              primary
              loading={loading}
              disabled={loading}
              onClick={submit}
              content="Сохранить"
            />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default CharactersForm;
