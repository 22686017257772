import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import { Button, Card, Image } from "semantic-ui-react";
import LessonsForm from "./form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setCurrentLesson } from "../../../redux/slices/lessonSlice";
import { useDispatch } from "react-redux";


const LessonsIndex = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { simulatorId } = useParams()
    const [lessonsModal, setLessonsModal] = useState(false)
    const [lessons, setLessons] = useState([])


    const findLessons = useCallback(async () => {
        const response = await api.get("lessons", {simulator: simulatorId})
        if (response.ok) {
            setLessons(await response.json())
        }
    }, [simulatorId])

    const onDragEnd = async (result) => {
        if (!result.destination) return;
        const endIndex = result.destination.index + 1

        const data = {
            order: endIndex
        }
        const response = await api.post(`lessons/${result.draggableId}/move/`, data)
        if (response.ok) {
            findLessons()
        }
    }

    const lessonDetails = (lesson) => {
        dispatch(setCurrentLesson(lesson))
        navigate(`${lesson.id}/pages`)
    }

    useEffect(() => {
        findLessons()
    }, [findLessons])

    return (
        <>
            <Button 
                basic 
                content="Добавить урок" 
                icon='add'
                onClick={() => setLessonsModal(true)}/>
            {
                lessonsModal &&
                <LessonsForm onClose={() => {findLessons(); setLessonsModal(false)}}/>
            }
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {
                        (provided, snapshot) => (
                            <div 
                                ref={provided.innerRef} 
                                {...provided.droppableProps}>
                                {
                                    lessons.map((lesson, index) => 
                                    <Draggable key={lesson.id} draggableId={`${lesson.id}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="row-card">
                                                <Card color={lesson.active ? "green" : 'red'} onClick={() => lessonDetails(lesson)} fluid link key={lesson.id}>
                                                    <Card.Content>
                                                        <Card.Header className="row-card-header">
                                                            <Image size="big" src={lesson.picture} />
                                                        </Card.Header>
                                                        <Card.Description>
                                                            <h3>{lesson.name}</h3>
                                                            <div dangerouslySetInnerHTML={{__html: lesson.description}}>
                                                            </div>
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            </div>
                                        )}
                                    </Draggable>)
                                }
                            {provided.placeholder}
                        </div>
                        )
                    }
                    
                </Droppable>
            </DragDropContext>
        </>
    )

}

export default LessonsIndex