import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import api from "../../api";
import { toast } from "react-toastify";
import TagCard from "../../components/tagCard";
import { RolesDropdown } from "../../components/rolesDropdown";

const GroupSettings = () => {
  const [form, setForm] = useState({
    name: "",
  });
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");

  const { currentGroup } = useSelector((state) => state.group);

  const submit = async () => {
    const response = await api.put(`simulators_group/${form.id}/`, form);
    if (response.ok) {
      toast.success("Группа изменена");
    }
  };

  const getGroupTags = useCallback(async () => {
    const response = await api.get(`tags/`, { group: currentGroup.id });
    if (response.ok) {
      setTags(await response.json());
    }
  }, [currentGroup.id]);

  const addTag = async (e) => {
    e.preventDefault();
    if (tagName !== "") {
      const payload = {
        group: currentGroup.id,
        name: tagName,
      };
      await api.post("tags/", payload);
      await getGroupTags();
      setTagName("");
    }
  };

  useEffect(() => {
    setForm(currentGroup);
  }, [currentGroup]);

  useEffect(() => {
    if (currentGroup) {
      getGroupTags();
    }
  }, [currentGroup, getGroupTags]);

  return (
    <>
      <Header content="Настройки группы" />
      {form.id && (
        <Form onSubmit={submit}>
          <Form.Input
            label="Имя"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            placeholder="Введите имя"
          />
          <Form.Field>
            <label>Роли доступа</label>
            <RolesDropdown
              value={form.roles}
              onChange={(roles) => setForm({ ...form, roles })}
            />
          </Form.Field>
          <Form.Field className="betweenFlex">
            <div></div>
            <Button type="submit" primary icon="save" content="Сохранить" />
          </Form.Field>
          <Form.Field></Form.Field>
        </Form>
      )}

      <Form onSubmit={addTag}>
        <Form.Group>
          <Form.Input
            label="Теги"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
          />
        </Form.Group>
        <Button
          type="submit"
          primary
          icon="plus circle"
          content="Добавить тег"
        />
      </Form>
      {tags.length > 0 ? (
        <div className="marginTop24">
          {tags.map((tag) => (
            <TagCard tag={tag} onDelete={getGroupTags} />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default GroupSettings;
