import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../api";
import { useDispatch } from "react-redux";
import { setAuthInfo } from "../../redux/slices/authSlice";



const AuthView = () => {
    const dispatch = useDispatch()
    const [form, setForm] = useState({
        username: "",
        password: ""
    })

    const submit = async () => {
        const response = await api.post("login/", form)
        if (response.ok) {
            const data = await response.json()
            dispatch(setAuthInfo(data))
            localStorage.setItem("authInfo", JSON.stringify(data))
        }
    }

    return (
        <Modal open dimmer="inverted">
            <Modal.Header>
                Войдите в систему
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        label='Имя пользователя'
                        onChange={(e) => setForm({...form, username: e.target.value})}
                        placeholder="Введите имя пользователя"
                        value={form.username}/>
                    <Form.Input
                        label='Пароль'
                        onChange={(e) => setForm({...form, password: e.target.value})}
                        placeholder="Введите пароль"
                        type="password"
                        value={form.password}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button primary content="Войти" icon="sign-in" onClick={submit}/>
            </Modal.Actions>
        </Modal>
    )
}

export default AuthView