import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import { Button, Card, Header, Image, Loader, Form } from "semantic-ui-react";
import SimulatorsForm from "./form";
import { useNavigate } from "react-router-dom";
import { setCurrentSimulator } from "../../redux/slices/simulatorSlice";
import { useDebounce } from "use-debounce";

const SimulatorsIndex = () => {
  const [addModal, setAddModal] = useState(false);
  const [simulators, setSimulators] = useState({
    results: [],
  });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    term: "",
    page: 1,
  });
  const [debounceValue] = useDebounce(params, 300);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentGroup } = useSelector((state) => state.group);

  const getNext = () => {
    const newParams = { ...params, page: params.page + 1 };
    setParams(newParams);
    getSimulators(newParams);
  };

  const simulatorDetails = (simulator) => {
    dispatch(setCurrentSimulator(simulator));
    navigate(`/simulators/${simulator.id}/lessons`);
  };

  const getSimulators = useCallback(
    async (params = { page: 1, term: "" }) => {
      setLoading(true);
      const response = await api.get("simulators", {
        ...params,
        group: currentGroup.id,
      });
      if (response.ok) {
        const data = await response.json();
        if (params.page > 1)
          setSimulators((state) => ({
            ...data,
            results: [...state.results, ...data.results],
          }));
        else setSimulators(data);
      }
      setLoading(false);
    },
    [currentGroup.id]
  );

  useEffect(() => {
    if (currentGroup) getSimulators();
  }, [currentGroup, getSimulators]);

  useEffect(() => {
    getSimulators(debounceValue);
  }, [debounceValue, getSimulators]);

  return (
    <>
      <div className=".flexBetweenBlock">
        <Header as="h1" content="Симуляторы" />
        <Button
          content="Добавить симулятор"
          icon="add"
          onClick={() => setAddModal(true)}
          basic
        />
      </div>
      <div>
        <Form>
          <Form.Input
            label="Поиск"
            value={params.term}
            onChange={(e) => setParams({ page: 1, term: e.target.value })}
            placeholder="Введите имя симулятора"
          />
        </Form>
      </div>
      <Card.Group itemsPerRow={3} className="marginTop24">
        {(simulators.results || []).map((simulator) => (
          <Card
            link
            onClick={() => simulatorDetails(simulator)}
            key={simulator.id}
          >
            <Image src={simulator.picture} wrapped ui={false} />
            <Card.Content>
              <Card.Header>{simulator.name}</Card.Header>
              <Card.Meta>
                <span className="date">{simulator.slug}</span>
              </Card.Meta>
              <Card.Description>{simulator.description}</Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      {simulators.next && (
        <div
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          <Button icon="add" content="Загрузить еще" onClick={getNext} />
        </div>
      )}
      {loading && (
        <div
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          <Loader active inline />
        </div>
      )}
      {addModal && (
        <SimulatorsForm
          onClose={() => {
            setAddModal(false);
            getSimulators();
          }}
        />
      )}
    </>
  );
};

export default SimulatorsIndex;
