import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../api";

export const ConnectionModal = ({ connectionData, onClose }) => {
  const [connection, setConnection] = useState({});
  const isNew = !connectionData.id;

  const submit = async () => {
    const response = isNew
      ? await api.post("places_links/", connection)
      : await api.put(`places_links/${connectionData.id}/`, connection);
    if (response.ok) {
      onClose();
    }
  };

  const submitDelete = async () => {
    const response = await api.delete(`places_links/${connection.id}/`);
    if (response.ok) {
      onClose();
    }
  };

  useEffect(() => {
    if (connectionData.id) {
      const getConnection = async () => {
        const response = await api.get(`places_links/${connectionData.id}/`);
        if (response.ok) {
          setConnection(await response.json());
        }
      };
      getConnection();
    } else {
      setConnection(connectionData);
    }
  }, [connectionData]);

  return (
    <Modal open size="small" onCLose={onClose}>
      <Modal.Header>
        {isNew ? "Создание связи" : "Редактирование связи"}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            type="number"
            value={connection.points}
            placeholder="Введите количество очков"
            onChange={(e) =>
              setConnection({ ...connection, points: e.target.value })
            }
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {!isNew && (
          <Button
            floated="left"
            color="red"
            icon="delete"
            content="Удалить"
            onClick={submitDelete}
            basic
          />
        )}
        <Button basic content="Заркыть" icon="close" onClick={onClose} />
        <Button primary content="Сохранить" icon="save" onClick={submit} />
      </Modal.Actions>
    </Modal>
  );
};
