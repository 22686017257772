import React, { useCallback, useEffect, useState } from "react";
import api from "../api";
import { useParams } from "react-router-dom";
import { Form } from "semantic-ui-react";


const TheoryChaptersDropdown = ({ value, onChange, label }) => {
    const { simulatorId } = useParams()
    const [chapters, setChapters] = useState([])
    const [term, setTerm] = useState("");
    const [opened, setOpened] = useState(false)


    const findChapters = useCallback(async () => {
        const response = await api.get("theory_chapters", {simulator: simulatorId})
        if (response.ok) {
            setChapters(await response.json())
        }
    }, [simulatorId])

    const getOptions = useCallback(() => {
        let options = opened ? chapters : chapters.filter(chap => chap.id === value)
        options = options.map(chap => ({
            key: chap.id,
            value: chap.id,
            text: chap.name,
        }))
        return options
    }, [chapters, value, opened])

    useEffect(() => {
        findChapters()
    }, [term, findChapters])

    return (
        <Form.Dropdown
            fluid
            search
            selection
            value={value}
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            onSearchChange={(e, { searchQuery }) => setTerm(searchQuery)}
            onChange={(e, { value }) => onChange(value)}
            options={getOptions()}
            clearable
            placeholder="Начните вводить"
            noResultsMessage="Ничего не найдено"
            selectOnBlur={false}
            label={label}/>
    )
     
}

export default TheoryChaptersDropdown