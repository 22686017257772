import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const imageUrl = `/api/v1/upload_image/`;

const HtmlEditor = ({ value, onChange }) => {
  return (
    <Editor
      apiKey="z1340qxai8uloeby3djzc7xyobck8ukml97lk2by0cvfobuo"
      init={{
        menubar: "insert format table tools help",
        plugins: "image paste",
        images_upload_url: imageUrl,
        images_upload_base_path: "",
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
      }}
      onEditorChange={onChange}
      value={value}
    />
  );
};
export default HtmlEditor;
