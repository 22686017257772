import React, { useCallback, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CroppaImage } from "../../components";

const SimulatorsForm = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    slug: "",
    name: "",
    description: "",
    picture: null,
  });
  const [errors, setErrors] = useState({});

  const { currentGroup } = useSelector((state) => state.group);

  const submit = useCallback(async () => {
    setLoading(true);
    const response = await api.post(
      "simulators/",
      { ...form, group: currentGroup.id },
      true
    );
    if (response.ok) {
      toast.success("Симулятор добавлен");
      onClose();
    } else {
      setErrors(await response.json());
    }
    setLoading(false);
  }, [form, currentGroup, onClose]);

  return (
    <Modal open onClose={onClose}>
      <Modal.Header>Добавление симулятора</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Input
              required
              value={form.slug}
              onChange={(e) => setForm({ ...form, slug: e.target.value })}
              label="Уникальный идентификатор"
              placeholder="Введите уникальный идентификатор"
              error={errors.slug}
            />
            <Form.Input
              required
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              label="Название"
              error={errors.name}
              placeholder="Введите название"
            />
          </Form.Group>
          <Form.Field>
            <label>Картинка</label>
            <CroppaImage
              onChange={(blob) =>
                setForm({
                  ...form,
                  picture: new File([blob], "simulator_picture.png"),
                })
              }
            />
          </Form.Field>
          {/* <Form.Input
            required
            label="Картинка"
            error={errors.picture}
            onChange={(e) => setForm({ ...form, picture: e.target.files[0] })}
            type="file"
          /> */}
          <Form.TextArea
            value={form.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            label="Описание"
            placeholder="Введите описание"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onClose} content="Закрыть" />
        <Button
          primary
          onClick={submit}
          disabled={loading}
          loading={loading}
          content="Сохранить"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SimulatorsForm;
