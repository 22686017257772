import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  groups: [],
  currentGroup: {}
}

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload
      state.currentGroup = action.payload[0]
    },
    setCurrentGroup: (state, action) => {
      state.currentGroup = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentGroup, setGroups } = groupSlice.actions
export default groupSlice.reducer