import React, { useCallback, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const PagesForm = ({onClose}) => {
    const [form, setForm] = useState({
        name: "",
    })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const { currentLesson } = useSelector(state => state.lesson)


    const submit = useCallback(async () => {
        setLoading(true)
        const response = await api.post("pages/", {...form, lesson: currentLesson.id})
        if (response.ok) {
            toast.success("Страница добавлена")
            onClose()
        } else {
            setErrors(await response.json())
        }
        setLoading(false)
    }, [form, currentLesson, onClose])


    return (
        <Modal size="small" open onClose={onClose}>
            <Modal.Header>Добавление страницы</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        required
                        value={form.name}
                        onChange={(e) => setForm({...form, name: e.target.value})}
                        label="Название"
                        error={errors.name}
                        placeholder="Введите название"/>
                    <Form.Input
                        value={form.description}
                        onChange={(e) => setForm({...form, description: e.target.value})}
                        label="Описание"
                        placeholder="Введите описание"/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    basic 
                    onClick={onClose}
                    content="Закрыть"/>
                <Button 
                    primary 
                    onClick={submit}
                    loading={loading}
                    disabled={loading}
                    content="Сохранить"/>
            </Modal.Actions>
        </Modal>
    )
}

export default PagesForm