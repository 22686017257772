import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import { setCurrentSimulator } from "../../redux/slices/simulatorSlice";
import api from "../../api";
import { Divider, Loader } from "semantic-ui-react";
import LessonsIndex from "./lessons";
import LessonsDetails from "../lessons";
import { Breadcrumbs } from "../../components";
import SimulatorSettings from "./settings";

const SimulatorDetails = () => {
  const { simulatorId } = useParams();
  const dispatch = useDispatch();
  const { currentSimulator } = useSelector((state) => state.simulator);

  const getSimulator = useCallback(async () => {
    const response = await api.get(`simulators/${simulatorId}`);
    if (response.ok) {
      dispatch(setCurrentSimulator(await response.json()));
    }
  }, [simulatorId, dispatch]);

  useEffect(() => {
    if (!currentSimulator && simulatorId) {
      getSimulator();
    }
  }, [getSimulator, currentSimulator, simulatorId]);

  useEffect(() => {
    return () => dispatch(setCurrentSimulator(null));
  }, [dispatch]);

  return currentSimulator ? (
    <>
      <Breadcrumbs />
      <Divider />
      <Routes>
        <Route path="lessons" element={<LessonsIndex />} />
        <Route path="lessons/:lessonId/*" element={<LessonsDetails />} />
        <Route
          path="settings"
          element={<SimulatorSettings onSave={getSimulator} />}
        />
      </Routes>
    </>
  ) : (
    <Loader active />
  );
};

export default SimulatorDetails;
