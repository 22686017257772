import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";



const GroupsDropdown = ({ value, onChange }) => {
    const [options, setOptions] = useState([])

    const { groups } = useSelector(state => state.group)


    useEffect(() => {
        setOptions(groups.map(group => ({text: group.name, value: group.id, key: group.id})))
    }, [groups])


    return (
        <Form.Dropdown
            options={options}
            fluid
            selection
            value={value}
            onChange={(e, { value }) => onChange(value)}/>
    )
}

export default GroupsDropdown