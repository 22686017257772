import React, { useState } from "react";
import { Form, Menu } from "semantic-ui-react";
import { MarkdownEditor, CharactersDropdown } from "../../";
import { PLACE_FORM_TABS } from "../../../constants";
import TemplatePlace from "./utils/templatePlace";




const Message = ({place, onChange}) => {
    const [activePage, setActivePage] = useState(PLACE_FORM_TABS.MAIN.key)
    
    return (
        <Form>
            <TemplatePlace 
                place={place} 
                onChange={onChange} 
                activePage={activePage} 
                setActivePage={setActivePage}
                additionalMenu={
                    <Menu.Item
                        content={PLACE_FORM_TABS.CHARACTER.title}
                        active={activePage === PLACE_FORM_TABS.CHARACTER.key}
                        onClick={() => setActivePage(PLACE_FORM_TABS.CHARACTER.key)}/>
                }/>
            {
                activePage === PLACE_FORM_TABS.MAIN.key &&
                <>
                    <Form.Input
                        label="Заголовок вопроса"
                        value={place.title}
                        placeholder="Введите заголовок"
                        required
                        onChange={(e) => onChange({title: e.target.value})}/>
                    <Form.Field required>
                        <label>Текст</label>
                        <MarkdownEditor
                            value={place.settings.text}
                            onChange={(text) => onChange({settings: {...place.settings, text}})}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Текст для женского обращения</label>
                        <MarkdownEditor
                            value={place.settings.female_text}
                            onChange={(female_text) => onChange({settings: {...place.settings, female_text}})}/>
                    </Form.Field>
                    <Form.Group inline>
                        <label>Расположение</label>
                        <Form.Radio
                            label='Слева'
                            value='left'
                            checked={place.settings.position === 'left'}
                            onChange={(e, { value }) => onChange({settings: {...place.settings, position: value}})}/>
                        <Form.Radio
                            label='Справа'
                            value='right'
                            checked={place.settings.position === 'right'}
                            onChange={(e, { value }) => onChange({settings: {...place.settings, position: value}})}/>
                    </Form.Group>
                </>
            }
            {
                activePage === PLACE_FORM_TABS.CHARACTER.key &&
                <>
                    <Form.Group widths={2}>
                        <Form.Field>
                            <label>Персонаж</label>
                            <CharactersDropdown
                                onChange={(character) => onChange({character})}
                                value={place.character}/>
                        </Form.Field>
                        <Form.Input
                            label="Переопределенная роль"
                            value={place.settings.forced_role}
                            placeholder="Введите роль персонажа"
                            onChange={(e) => onChange({settings: {...place.settings, forced_role: e.target.value}})}/>
                    </Form.Group>
                    <Form.Checkbox
                        label="Персонаж пользователя"
                        checked={place.settings.is_user_character}
                        onChange={(e, { checked }) => onChange({settings: {...place.settings, is_user_character: checked}})}/>
                </>
            }
        </Form>
    )

}

export default Message