import React, { useState } from "react";
import { Button, Card, Form, Menu, Modal } from "semantic-ui-react";
import { MarkdownEditor } from "../..";
import { PLACE_FORM_TABS } from "../../../constants";
import TemplatePlace from "./utils/templatePlace";

const DEFAULT_ANSWER = {
  text: "",
  value: "",
  points: 0,
  send_gpt: false,
};

const AnswersBlock = ({ answers, onChange }) => {
  const [answerModal, setAnswerModal] = useState();

  const submitAnswerModal = () => {
    if (answerModal.isNew) {
      onChange({ answer: [...answers, answerModal.answer] });
    } else {
      onChange({
        answer: answers.map((ans, idx) =>
          answerModal.idx === idx ? { ...answerModal.answer } : ans
        ),
      });
    }
    setAnswerModal();
  };

  const deleteAnswer = () => {
    onChange({ answer: answers.filter((ans, idx) => answerModal.idx !== idx) });
    setAnswerModal();
  };

  return (
    <>
      <br />
      <br />
      <Card.Group itemsPerRow={3} className="marginBottom24">
        {answers.map((answer, idx) => (
          <Card
            link
            onClick={() => setAnswerModal({ answer, idx })}
            key={`answer_${idx}`}
            color={answer.is_right ? "green" : "red"}
          >
            <Card.Content>
              <Card.Header>{answer.text}</Card.Header>
              <Card.Meta>{answer.points}</Card.Meta>
              <Card.Description>{answer.comment}</Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      <Button
        onClick={() => setAnswerModal({ answer: DEFAULT_ANSWER, isNew: true })}
        basic
        content="Добавить ответ"
        icon="add"
        primary
      />
      {answerModal && (
        <Modal
          centered={false}
          size="large"
          open
          onClose={() => setAnswerModal()}
        >
          <Modal.Header>Добавление ответа</Modal.Header>
          <Modal.Content scrolling>
            <Form>
              <Form.Group widths={2}>
                <Form.Input
                  required
                  label="Значение"
                  placeholder="Введите значение"
                  value={answerModal.answer.value}
                  onChange={(e) =>
                    setAnswerModal({
                      ...answerModal,
                      answer: { ...answerModal.answer, value: e.target.value },
                    })
                  }
                />
                <Form.Input
                  required
                  label="Текст"
                  placeholder="Введите текст"
                  value={answerModal.answer.text}
                  onChange={(e) =>
                    setAnswerModal({
                      ...answerModal,
                      answer: { ...answerModal.answer, text: e.target.value },
                    })
                  }
                />
              </Form.Group>
              <Form.Input
                required
                label="Количество очков"
                type="number"
                placeholder="Введите количество очков"
                value={answerModal.answer.points}
                onChange={(e) =>
                  setAnswerModal({
                    ...answerModal,
                    answer: { ...answerModal.answer, points: e.target.value },
                  })
                }
              />
              <Form.Checkbox
                label="Отправить в GPT"
                checked={answerModal.answer.send_gpt}
                onChange={(e, { checked }) =>
                  setAnswerModal({
                    ...answerModal,
                    answer: { ...answerModal.answer, send_gpt: checked },
                  })
                }
              />
            </Form>
          </Modal.Content>
          <Modal.Actions className="flexBetweenBlock">
            <div>
              {answerModal.isNew ? (
                <div></div>
              ) : (
                <Button
                  onClick={deleteAnswer}
                  color="red"
                  basic
                  content="Удалить"
                  icon="trash"
                />
              )}
            </div>
            <div>
              <Button
                content="Закрыть"
                basic
                icon="close"
                onClick={() => setAnswerModal()}
              />
              <Button
                content="Сохранить"
                primary
                icon="close"
                onClick={submitAnswerModal}
              />
            </div>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

const AiOpenQuestion = ({ place, onChange }) => {
  const [activePage, setActivePage] = useState(PLACE_FORM_TABS.MAIN.key);

  return (
    <Form>
      <TemplatePlace
        place={place}
        onChange={onChange}
        activePage={activePage}
        setActivePage={setActivePage}
        additionalMenu={
          <Menu.Item
            content={PLACE_FORM_TABS.ANSWERS.title}
            active={activePage === PLACE_FORM_TABS.ANSWERS.key}
            onClick={() => setActivePage(PLACE_FORM_TABS.ANSWERS.key)}
          />
        }
      />
      {activePage === PLACE_FORM_TABS.MAIN.key && (
        <>
          <Form.Input
            label="Заголовок вопроса"
            value={place.title}
            placeholder="Введите заголовок"
            required
            onChange={(e) => onChange({ title: e.target.value })}
          />
          <Form.Input
            label="ID запроса"
            value={place.ml_request_id}
            placeholder="Введите id"
            required
            onChange={(e) => onChange({ ml_request_id: e.target.value })}
          />
          <Form.Field required>
            <label>Текст</label>
            <MarkdownEditor
              value={place.settings.text}
              onChange={(text) =>
                onChange({ settings: { ...place.settings, text } })
              }
            />
          </Form.Field>
          <Form.Field required>
            <label>Текст для женского обращения</label>
            <MarkdownEditor
              value={place.settings.female_text}
              onChange={(female_text) =>
                onChange({ settings: { ...place.settings, female_text } })
              }
            />
          </Form.Field>
          <Form.TextArea
            label="Описание"
            value={place.description}
            placeholder="Введите описание"
            onChange={(e) => onChange({ description: e.target.value })}
          />
        </>
      )}
      {activePage === PLACE_FORM_TABS.ANSWERS.key && (
        <>
          <AnswersBlock answers={place.answer || []} onChange={onChange} />
          <Form.Field required className="marginTop24">
            <label>Комментарий после ответа</label>
            <MarkdownEditor
              value={place.settings.comment}
              onChange={(comment) =>
                onChange({ settings: { ...place.settings, comment } })
              }
            />
          </Form.Field>
          <Form.Field required>
            <label>Женский комментарий после ответа</label>
            <MarkdownEditor
              value={place.settings.female_comment}
              onChange={(female_comment) =>
                onChange({ settings: { ...place.settings, female_comment } })
              }
            />
          </Form.Field>
        </>
      )}
    </Form>
  );
};

export default AiOpenQuestion;
