import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPage: null
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentPage } = pageSlice.actions
export default pageSlice.reducer