import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import Logo from "../images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { Button, Image } from "semantic-ui-react";
import GroupsDropdown from "./groupsDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentGroup } from "../redux/slices/groupSlice";

const GROUP_ITEMS = [
  {
    path: "/simulators",
    text: "Симуляторы",
    key: "simulators",
  },
  {
    path: "/characters",
    text: "Персонажи",
    key: "characters",
  },
  {
    path: "/theory",
    text: "Теория",
    key: "theory",
  },
  {
    path: "/settings",
    text: "Настройки",
    key: "settings",
  },
];

const SIMULATOR_ITEMS = [
  {
    path: "/lessons",
    text: "Уроки",
    key: "lessons",
  },
  {
    path: "/settings",
    text: "Настройки",
    key: "settings",
  },
];

const LESSON_ITEMS = [
  {
    path: "/pages",
    text: "Страницы",
    key: "pages",
  },
  {
    path: "/settings",
    text: "Настройки",
    key: "settings",
  },
];

const PAGE_ITEMS = [
  {
    path: "/content",
    text: "Контент",
    key: "content",
  },
  {
    path: "/settings",
    text: "Настройки",
    key: "settings",
  },
];

const GroupMenu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { currentGroup, groups } = useSelector((state) => state.group);

  const selectCurrentGroup = (id) => {
    dispatch(setCurrentGroup(groups.find((group) => group.id === id)));
  };

  return (
    <>
      <MenuItem className="dropdownMenuItem">
        <GroupsDropdown value={currentGroup.id} onChange={selectCurrentGroup} />
      </MenuItem>
      <br />
      {GROUP_ITEMS.map((item) => (
        <MenuItem
          key={item.key}
          component={<Link to={item.path} />}
          active={pathname === item.path}
        >
          <Button fluid primary={pathname === item.path} content={item.text} />
        </MenuItem>
      ))}
    </>
  );
};

const SimulatorMenu = ({ simulatorId }) => {
  const { pathname } = useLocation();

  return (
    <>
      {SIMULATOR_ITEMS.map((item) => (
        <MenuItem
          key={item.key}
          component={<Link to={`/simulators/${simulatorId}${item.path}`} />}
          active={pathname.includes(item.path)}
        >
          <Button
            fluid
            primary={pathname.includes(item.path)}
            content={item.text}
          />
        </MenuItem>
      ))}
    </>
  );
};

const LessonMenu = ({ simulatorId, lessonId }) => {
  const { pathname } = useLocation();
  return (
    <>
      {LESSON_ITEMS.map((item) => (
        <MenuItem
          key={item.key}
          component={
            <Link
              to={`/simulators/${simulatorId}/lessons/${lessonId}${item.path}`}
            />
          }
          active={pathname.includes(item.path)}
        >
          <Button
            fluid
            primary={pathname.includes(item.path)}
            content={item.text}
          />
        </MenuItem>
      ))}
    </>
  );
};

const PageMenu = ({ simulatorId, lessonId, pageId }) => {
  const { pathname } = useLocation();

  return (
    <>
      {PAGE_ITEMS.map((item) => (
        <MenuItem
          key={item.key}
          component={
            <Link
              to={`/simulators/${simulatorId}/lessons/${lessonId}/pages/${pageId}${item.path}`}
            />
          }
          active={pathname.includes(item.path)}
        >
          <Button
            fluid
            primary={pathname.includes(item.path)}
            content={item.text}
          />
        </MenuItem>
      ))}
    </>
  );
};

const MainSidebar = () => {
  const { currentSimulator } = useSelector((state) => state.simulator);
  const { currentLesson } = useSelector((state) => state.lesson);
  const { currentPage } = useSelector((state) => state.page);

  return (
    <Sidebar style={{ width: "300px" }} backgroundColor="white">
      <Menu style={{ minHeight: "100vh", padding: "10px" }}>
        <MenuItem disabled className="brandSidebarItem">
          <Image src={Logo} width={77} height={68} />
        </MenuItem>
        {currentPage ? (
          <PageMenu
            lessonId={currentLesson.id}
            simulatorId={currentSimulator.id}
            pageId={currentPage.id}
          />
        ) : currentLesson ? (
          <LessonMenu
            lessonId={currentLesson.id}
            simulatorId={currentSimulator.id}
          />
        ) : currentSimulator ? (
          <SimulatorMenu simulatorId={currentSimulator.id} />
        ) : (
          <GroupMenu />
        )}
      </Menu>
    </Sidebar>
  );
};

export default MainSidebar;
