import React, { useCallback, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../../api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { MarkdownEditor } from '../../../components'


const LessonsForm = ({onClose}) => {
    const { simulatorId } = useParams()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        name: "",
        description: "",
        picture: null
    })
    const [errors, setErrors] = useState({})


    const submit = useCallback(async () => {
        setLoading(true)
        const response = await api.post("lessons/", {...form, simulator: simulatorId}, true)
        if (response.ok) {
            toast.success("Урок добавлен")
            onClose()
        } else {
            setErrors(await response.json())
        }
        setLoading(false)
    }, [form, simulatorId, onClose])


    return (
        <Modal open onClose={onClose}>
            <Modal.Header>Добавление урока</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        required
                        value={form.name}
                        onChange={(e) => setForm({...form, name: e.target.value})}
                        label="Название"
                        error={errors.name}
                        placeholder="Введите название"/>
                    <Form.Input 
                        required
                        label="Картинка"
                        error={errors.picture}
                        onChange={(e) => setForm({...form, picture: e.target.files[0]})}
                        type="file"/>
                    <Form.Field>
                        <label>Описание</label>
                        <MarkdownEditor
                            value={form.description}
                            onChange={(description) => setForm({...form, description})}/>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    basic 
                    onClick={onClose}
                    content="Закрыть"/>
                <Button 
                    primary 
                    disabled={loading}
                    loading={loading}
                    onClick={submit}
                    content="Сохранить"/>
            </Modal.Actions>
        </Modal>
    )
}

export default LessonsForm