import React, { useCallback, useEffect, useState } from "react";
import api from "../../../api";
import { Button, Card } from "semantic-ui-react";
import TheoriesForm from "./form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

const TheoriesIndex = () => {
  const [theoryModalData, setTheoryModalData] = useState(false);
  const [theories, setTheories] = useState([]);

  const { currentGroup } = useSelector((state) => state.group);

  const getTheories = useCallback(async () => {
    const response = await api.get("theory_chapters", {
      group: currentGroup.id,
    });
    if (response.ok) {
      setTheories(await response.json());
    }
  }, [currentGroup]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const endIndex = result.destination.index + 1;

    const data = {
      order: endIndex,
    };
    const response = await api.post(
      `theory_chapters/${result.draggableId}/move/`,
      data
    );
    if (response.ok) {
      getTheories();
    }
  };

  useEffect(() => {
    if (currentGroup) getTheories();
  }, [currentGroup, getTheories]);

  return (
    <>
      <Button
        content="Добавить"
        icon="add"
        onClick={() => setTheoryModalData({ isNew: true })}
        basic
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {theories.map((theory, index) => (
                <Draggable
                  key={theory.id}
                  draggableId={`${theory.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="row-card"
                    >
                      <Card
                        onClick={() =>
                          setTheoryModalData({ isNew: false, id: theory.id })
                        }
                        fluid
                        link
                        key={theory.id}
                      >
                        <Card.Content>
                          <Card.Header className="row-card-header">
                            {theory.name}
                          </Card.Header>
                        </Card.Content>
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {theoryModalData && (
        <TheoriesForm
          theory={theoryModalData}
          onClose={() => {
            setTheoryModalData(false);
            getTheories();
          }}
        />
      )}
    </>
  );
};

export default TheoriesIndex;
