import React, { useEffect, useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import Elements from "./elements";

const getSvgCursorPosition = (e, svg) => {
  const pt = svg.createSVGPoint();
  pt.x = e.clientX;
  pt.y = e.clientY;
  return pt.matrixTransform(svg.getScreenCTM().inverse());
};

const initialMoovingData = {
  isMooving: false,
  initialX: 0,
  initialY: 0,
  currentX: 0,
  currentY: 0,
};

const initialMovePlaceData = {
  id: "",
  initialX: 0,
  initialY: 0,
  currentX: 0,
  currentY: 0,
};

const initialConnectionLineData = {
  fromPosition: {
    x: 0,
    y: 0,
  },
  toPosition: {
    x: 0,
    y: 0,
  },
  inProgress: false,
  fromPlace: null,
  toPlace: null,
};

const Diagram = ({
  selectedPlace,
  setSelectedPlace,
  places = [],
  setPlaces,
  onSelectConnection,
  onPlaceChanged,
}) => {
  const svgRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [movePlaceData, setMovePlaceData] = useState(initialMovePlaceData);
  const [moveContainerData, setMoveContainerData] =
    useState(initialMoovingData);
  const [connectionLineData, setConnectionLineData] = useState(
    initialConnectionLineData
  );

  const handleMouseMove = (e) => {
    const position = getSvgCursorPosition(e, svgRef.current);
    if (selectedPlace && selectedPlace.isNew) {
      setSelectedPlace((el) => ({ ...el, x: position.x, y: position.y }));
    } else if (moveContainerData.isMooving) {
      const dX = moveContainerData.initialX - position.x;
      const dY = moveContainerData.initialY - position.y;
      setMoveContainerData((data) => ({
        ...data,
        currentX: data.currentX + dX,
        currentY: data.currentY + dY,
      }));
    } else if (movePlaceData.id) {
      const dX = (e.clientX - movePlaceData.currentX) / zoom;
      const dY = (e.clientY - movePlaceData.currentY) / zoom;

      setPlaces(
        places.map((place) =>
          place.id === movePlaceData.id
            ? {
                ...place,
                x: movePlaceData.initialX + dX,
                y: movePlaceData.initialY + dY,
              }
            : place
        )
      );
    } else if (connectionLineData.inProgress) {
      setConnectionLineData({
        ...connectionLineData,
        toPosition: position,
      });
    }
  };

  const handleMouseUp = (e) => {
    if (selectedPlace && !selectedPlace.id) {
      setSelectedPlace({ ...selectedPlace, settings: {}, edit: true });
    }
    setMoveContainerData({
      ...moveContainerData,
      initialX: 0,
      initialY: 0,
      isMooving: false,
    });
    if (movePlaceData.id) {
      const place = places.find((place) => place.id === movePlaceData.id);
      if (
        place.x === movePlaceData.initialX &&
        place.y === movePlaceData.initialY
      ) {
        setSelectedPlace({ ...place, edit: true });
      } else {
        onPlaceChanged(place);
      }
      setMovePlaceData(initialMovePlaceData);
    }
  };

  const handleMouseDown = (e) => {
    const position = getSvgCursorPosition(e, svgRef.current);
    setMoveContainerData({
      ...moveContainerData,
      isMooving: true,
      initialX: position.x,
      initialY: position.y,
    });
  };

  const handlePlaceMouseDown = (e, place) => {
    e.stopPropagation();
    setMovePlaceData({
      id: place.id,
      initialX: place.x,
      initialY: place.y,
      currentX: e.clientX,
      currentY: e.clientY,
    });
  };

  const handleZoom = (e) => {
    if (e.ctrlKey || e.metaKey) {
      const zoomFactor = 1.02;
      const deltaY = e.deltaY;
      if (Number(deltaY) === deltaY && deltaY % 1 !== 0) {
        e.preventDefault();
        if (deltaY > 0) {
          setZoom((zoom) =>
            zoom / zoomFactor < 0.5 ? 0.5 : zoom / zoomFactor
          );
        } else {
          setZoom((zoom) => (zoom * zoomFactor > 2 ? 2 : zoom * zoomFactor));
        }
      }
    }
  };

  const startPlaceConnection = (e, fromPlace) => {
    const position = getSvgCursorPosition(e, svgRef.current);
    setConnectionLineData({
      ...connectionLineData,
      inProgress: true,
      fromPosition: position,
      toPosition: position,
      fromPlace,
    });
  };
  const endPlaceConnection = (toPlace) => {
    if (connectionLineData.inProgress) {
      setConnectionLineData({
        ...connectionLineData,
        inProgress: false,
      });
      onSelectConnection({
        start_place: connectionLineData.fromPlace.id,
        end_place: toPlace.id,
        points: 0,
      });
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Escape") {
      setConnectionLineData(initialConnectionLineData);
      setSelectedPlace();
    }
  };

  const renderConnectionLine = (
    fromPosition,
    toPosition,
    idx = "initial",
    label,
    lineId
  ) => {
    let d = "";
    const { x: x1, y: y1 } = fromPosition;
    const { x: x2, y: y2 } = toPosition;
    let textX;
    let textY;
    if (y1 < y2) {
      const dY = (y2 - y1) / 2 + y1;
      textY = dY - 5;
      textX = x1 > x2 ? x1 - (x1 - x2) / 2 : x2 - (x2 - x1) / 2 + 5;
      d = `M ${x1} ${y1} L ${x1} ${dY} ${x2} ${dY} ${x2} ${y2}`;
    } else {
      const dX = x1 > x2 ? x1 - (x1 - x2) / 2 : x2 - (x2 - x1) / 2;
      const dY = y1 + 20;
      const dY2 = y2 - 20;
      textY = dY2 + (dY - dY2) / 2;
      textX = dX + 5;
      d = `M ${x1} ${y1} L ${x1} ${dY} ${dX} ${dY} ${dX} ${dY2} ${x2} ${dY2} ${x2} ${y2}`;
    }

    const onClick = (e) => {
      e.stopPropagation();
      if (lineId) {
        onSelectConnection({ id: lineId });
      }
    };
    return (
      <g onClick={onClick}>
        <text x={textX} y={textY}>
          {label}
        </text>
        <path
          style={{ cursor: "pointer" }}
          key={`connection_line_${idx}`}
          d={d}
          stroke="#2185d0"
          fill="transparent"
          strokeWidth={3}
        />
      </g>
    );
  };

  const getPlaceSvg = (place) => {
    const width = 150 * zoom;
    const height = 75 * zoom;
    return (
      <React.Fragment key={place.id}>
        <g onMouseDown={(e) => handlePlaceMouseDown(e, place)}>
          <rect
            rx="5"
            x={place.x}
            y={place.y}
            width={width}
            height={height}
            fill="white"
          />
          <foreignObject x={place.x} y={place.y} width={width} height={height}>
            <div
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                cursor: "pointer",
                fontSize: `${14 * zoom}px`,
              }}
              className="svgText"
            >
              {place.title}
            </div>
          </foreignObject>
          <circle
            className="placeCircle"
            r={10}
            cx={place.x + width / 2}
            cy={place.y}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => endPlaceConnection(place)}
            fill="#e0e1e2"
          />
          <circle
            className="placeCircle"
            r={10}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => startPlaceConnection(e, place)}
            cx={place.x + width / 2}
            cy={place.y + height}
            fill="#e0e1e2"
          />
        </g>
        {(place.next_places || []).map((link, idx) => {
          const fromPosition = { x: place.x + width / 2, y: place.y + height };
          const toPlace = places.find((place) => link.end_place === place.id);
          const toPosition = { x: toPlace.x + width / 2, y: toPlace.y };
          return renderConnectionLine(
            fromPosition,
            toPosition,
            idx,
            link.points,
            link.id
          );
        })}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (svgRef.current) {
      const svgSize = svgRef.current.getBoundingClientRect();
      const viewBoxY = svgSize.height / zoom;
      const viewBoxX = svgSize.width / zoom;
      const minViewBoxX = moveContainerData.currentX;
      const minViewBoxY = moveContainerData.currentY;
      svgRef.current.setAttribute(
        "viewBox",
        `${minViewBoxX} ${minViewBoxY} ${viewBoxX} ${viewBoxY}`
      );
      svgRef.current.addEventListener("wheel", handleZoom, { passive: false });
      return () =>
        svgRef.current &&
        svgRef.current.removeEventListener("wheel", handleZoom);
    }
  }, [svgRef, zoom, moveContainerData]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <Elements onMouseDown={setSelectedPlace} />
        </Grid.Column>
        <Grid.Column width={12}>
          <svg
            style={{
              width: "100%",
              cursor: "move",
              height: "100%",
              paddingRight: "15px",
            }}
            tabIndex={0}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onKeyUp={handleKeyUp}
            onMouseMove={handleMouseMove}
            preserveAspectRatio="xMinYMin meet"
            ref={svgRef}
          >
            <defs>
              <pattern
                id="smallGrid"
                width="10"
                height="10"
                patternUnits="userSpaceOnUse"
              >
                <path
                  d="M 10 0 L 0 0 0 10"
                  fill="none"
                  stroke="gray"
                  strokeWidth="0.5"
                />
              </pattern>
              <pattern
                id="mediumGrid"
                width="100"
                height="100"
                patternUnits="userSpaceOnUse"
              >
                <rect width="100" height="100" fill="url(#smallGrid)" />
                <path
                  d="M 100 0 L 0 0 0 100"
                  fill="none"
                  stroke="gray"
                  strokeWidth="1"
                />
              </pattern>
              <pattern
                id="grid"
                width="1000"
                height="1000"
                patternUnits="userSpaceOnUse"
              >
                <rect width="1000" height="1000" fill="url(#mediumGrid)" />
                <path
                  d="M 1000 0 L 0 0 0 1000"
                  fill="none"
                  stroke="gray"
                  strokeWidth="1"
                />
              </pattern>
            </defs>
            <rect
              x="-1000px"
              y="-1000px"
              width={"10000%"}
              height={"10000%"}
              fill="url(#grid)"
            />
            {connectionLineData.inProgress &&
              renderConnectionLine(
                connectionLineData.fromPosition,
                connectionLineData.toPosition
              )}
            {selectedPlace && !selectedPlace.id && getPlaceSvg(selectedPlace)}

            {places.map((place) => getPlaceSvg(place))}
          </svg>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Diagram;
