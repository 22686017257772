import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Menu } from "semantic-ui-react";
import api from "../../api";
import { toast } from "react-toastify";
import { SETTINGS_PAGES } from "../../constants";
import PermissionsIndex from "../permissions";
import { CroppaImage } from "../../components";
import { RolesDropdown } from "../../components/rolesDropdown";
var FileSaver = require("file-saver");

const SimulatorSettings = ({ onSave }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState("main");

  const [allTags, setAllTags] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);

  const [chosenTags, setChosenTags] = useState([]); // Отслеживание текущих выбранных тегов

  const { currentSimulator: simulator } = useSelector(
    (state) => state.simulator
  );

  const submit = async () => {
    const body = { ...form, tags: chosenTags };
    delete body["picture"];
    const response = await api.patch(`simulators/${form.id}/`, body);
    if (response.ok) {
      toast.success("Симулятор изменен");
      onSave();
    } else {
      const data = await response.json();
      setErrors(data || {});
    }
  };

  const exportSimulator = useCallback(async () => {
    const response = await api.post("export/", {
      type: "simulator",
      id: simulator.id,
    });
    if (response.ok) {
      FileSaver.saveAs(
        await response.blob(),
        `simulator_${simulator.name}_${simulator.id}.json`
      );
    }
  }, [simulator]);

  const getSimulatorTags = useCallback(async () => {
    const response = await api.get(`tags/`, { group: simulator.group });
    if (response.ok) {
      const tags = await response.json();
      setAllTags(tags);
    }
  }, [simulator.group.id]);

  const chooseTag = (e, { value }) => {
    setChosenTags([...value]);
  };

  const tagToOption = (tag) => ({
    key: tag.id,
    text: tag.name,
    value: tag.id,
  });

  const createTag = async (e, { value }) => {
    if (value !== "") {
      const payload = {
        group: simulator.group,
        name: value,
        simulators: [simulator.id],
      };
      const response = await api.post("tags/", payload);
      if (response.ok) {
        const tag = await response.json();
        setAllTags([...allTags, tag]);
        setChosenTags([...chosenTags, tag.id]);
      }
    }
  };

  useEffect(() => {
    setForm({ ...simulator, settings: simulator.settings || {} });
  }, [simulator]);

  useEffect(() => {
    if (simulator) {
      getSimulatorTags();
    }
  }, [simulator, getSimulatorTags]);

  useEffect(() => {
    setTagsOptions(allTags.map(tagToOption));
    setChosenTags(
      allTags
        .filter((tag) => tag.simulators.indexOf(simulator.id) !== -1)
        .map((tag) => tag.id)
    );
  }, [allTags, simulator.id]);

  return (
    <>
      <Menu secondary pointing widths={2}>
        {Object.keys(SETTINGS_PAGES).map((key) => (
          <Menu.Item
            key={key}
            content={SETTINGS_PAGES[key]}
            active={page === key}
            onClick={() => setPage(key)}
          />
        ))}
      </Menu>
      {form.id && page === "main" && (
        <Form>
          <Form.Group widths={2}>
            <Form.Input
              label="Уникальный идентификатор"
              required
              placeholder="Введите идентификатор"
              onChange={(e) => setForm({ ...form, slug: e.target.value })}
              error={errors.slug}
              value={form.slug}
            />
            <Form.Input
              label="Название"
              required
              placeholder="Введите название"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              error={errors.name}
              value={form.name}
            />
          </Form.Group>
          <Form.Field>
            <CroppaImage onChange={console.log} />
          </Form.Field>
          <Form.TextArea
            label="Описание"
            placeholder="Введите описание"
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            value={form.name}
          />
          <Form.Input
            type="number"
            label="Время прохождения"
            placeholder="Введите время"
            onChange={(e, { value }) =>
              setForm({
                ...form,
                settings: { ...form.settings, complete_time: value },
              })
            }
            value={form.settings.complete_time}
          />
          <Form.Group>
            <Form.Checkbox
              label="Нужна подписка"
              onChange={(e, { checked }) =>
                setForm({ ...form, need_subscription: checked })
              }
              checked={form.need_subscription}
            />
          </Form.Group>
          <Form.Dropdown
            label="Теги"
            placeholder="Выберите теги симулятора"
            fluid
            search
            multiple
            selection
            allowAdditions
            options={tagsOptions}
            value={chosenTags}
            onChange={chooseTag}
            onAddItem={createTag}
          />
          <Form.Field>
            <label>Роли доступа</label>
            <RolesDropdown
              value={form.roles || []}
              onChange={(roles) => setForm({ ...form, roles })}
            />
          </Form.Field>
          <div className="betweenFlex">
            <Button
              basic
              onClick={exportSimulator}
              icon="download"
              content="Скачать"
            />
            <Button primary onClick={submit} content="Сохранить" />
          </div>
        </Form>
      )}
      {form.id && page === "permissions" && <PermissionsIndex />}
    </>
  );
};

export default SimulatorSettings;
