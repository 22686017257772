
export const PLACE_TYPES = {
    theory: "theory",
    safetext: "safetext",
    message: "message",
    question: "question",
    openquestion: "openquestion",
    openquestionexpert: "openquestionexpert",
    questionanswercheck: "questionanswercheck",
    questionuserchoice: "questionuserchoice",
    questionrange: "questionrange",
    questionexternal: "questionexternal",
    aiopenquestion: "aiopenquestion"

}

export const PLACES = {
    [PLACE_TYPES.theory]: {
        title: 'Теория',
        icon: "book"
    },
    [PLACE_TYPES.safetext]: {
        title: 'Текст',
        icon: "bold"
    },
    [PLACE_TYPES.message]: {
        title: 'Сообщение',
        icon: "comment"
    },
    [PLACE_TYPES.question]: {
        title: 'Вопрос',
        icon: "question"
    },
    [PLACE_TYPES.openquestion]: {
        title: 'Открытый вопрос',
        icon: "question circle outline"
    },
    [PLACE_TYPES.openquestionexpert]: {
        title: 'Открытый вопрос эксперту',
        icon: "user secret"
    },
    [PLACE_TYPES.questionanswercheck]: {
        title: 'Открытый вопрос с проверкой',
        icon: "question circle"
    },
    [PLACE_TYPES.questionuserchoice]: {
        title: 'Вопрос с выбором пользователя',
        icon: "add user"
    },
    [PLACE_TYPES.questionrange]: {
        title: 'Открытый вопрос на интервале',
        icon: "sliders horizontal"
    },
    [PLACE_TYPES.questionexternal]: {
        title: 'Внешний вопрос',
        icon: "external alternate"
    },
    [PLACE_TYPES.aiopenquestion]: {
        title: 'Вопрос с ИИ',
        icon: "graduation cap"
    },
}

export const PLACE_FORM_TABS = {
    MAIN: {
        title: "Общая информация",
        key: 'main'
    },
    SCRIPTS: {
        title: "Скрипты",
        key: 'scripts'
    },
    PASSAGE: {
        title: "Прохождение",
        key: 'passage'
    },
    CHARACTER: {
        title: "Персонаж",
        key: 'character'
    },
    ANSWERS: {
        title: "Ответы",
        key: 'answers'
    },
}

export const SETTINGS_PAGES = {
    "main": "Основная информация",
    "permissions": "Права доступа"
}

export const PERMISSION_LEVELS = {
    owner: "Владелец",
    writer: "Редактор",
    reader: "Просмотр"
}

export const PERMISSION_LEVELS_OPTIONS = Object.keys(PERMISSION_LEVELS).map(key => ({key, value: key, text: PERMISSION_LEVELS[key]}))